@tailwind base;
@tailwind components;
@tailwind utilities;
/* @font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmhduz8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwkxduz8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmxduz8A.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlBduz8A.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmBduz8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwmRduz8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlxdu.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/UniversLTCYR-67BoldCond-Huyagaa.ttf) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/UniversLTCYR-67BoldCond-Huyagaa.ttf) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/UniversLTCYR-67BoldCond-Huyagaa.ttf) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/UniversLTCYR-67BoldCond-Huyagaa.ttf) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* 
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/Inter/static/Inter_18pt-Thin.ttf") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/Inter/static/Inter_18pt-ExtraLight.ttf") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Inter/static/Inter_18pt-Light.ttf") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Inter/static/Inter_18pt-Regular.ttf") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Inter/woff/Inter-Regular.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Inter/woff/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Inter/woff/Inter-SemiBold.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Inter/woff/Inter-Bold.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Inter/woff/Inter-Bold.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/Inter/woff/Inter-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Inter/woff/Inter-Black.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-display: swap;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Inter/woff/Inter-Black.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
} */
