@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.woff?h3b8we') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow-right:before {
  content: "\e903";
}
.instagram:before {
  content: "\e900";
}
.github:before {
  content: "\e901";
}
.linkedin:before {
  content: "\e902";
}
.icon-play3:before {
    content: "\e909";
}