* {
  box-sizing: border-box;
}

img.alignright {
  float: right;
  margin: 0 0 20px 20px;
}

img.alignleft {
  float: left;
  margin: 0 20px 20px 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
}

img.size-full {
  max-width: 100%;
  height: auto;
}

a,
a img,
a:before,
a:after,
a span:not([class^="icon-"]),
input,
input:hover,
textarea,
textarea:hover,
button,
button:after,
button:before,
nav li a,
nav li a:after,
[class^="icon-"],
[class*=" icon-"],
[class^="icon-"]:after,
[class*=" icon-"]:after,
.division-button span,
.division-button span:after,
.button,
.button:after,
.button:before,
.button-image--name {
  transition: all 0.3s ease;
}

::-moz-selection {
  background: #fc0;
  color: #fff;
}

::selection {
  background: #fc0;
  color: #fff;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  padding: 0;
  color: #1c1c1c;
  font-weight: 700;
  line-height: 1.16em;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
}

h1 span,
.h1 span,
h2 span,
.h2 span,
h3 span,
.h3 span,
h4 span,
.h4 span,
h5 span,
.h5 span,
h6 span,
.h6 span {
  font-weight: 400;
}

h1,
.h1 {
  line-height: 1.125em;
  font-size: 32px;
  padding: 0 0 14px;
}

h2,
.h2 {
  font-size: 30px;
  line-height: 1.16em;
  padding: 0 0 12px;
}

h3,
.h3 {
  font-size: 24px;
  line-height: 1.16em;
  padding: 0 0 10px;
}

h4,
.h4 {
  font-size: 20px;
  padding: 0 0 8px;
}

h5,
.h5 {
  font-size: 15px;
  padding: 0 0 6px;
}

h6,
.h6 {
  font-size: 14px;
  padding: 0 0 6px;
}

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 44px;
    line-height: 1.13em;
  }

  h2,
  .h2 {
    font-size: 36px;
    line-height: 1.11em;
  }

  h4,
  .h4 {
    font-size: 26px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }
}

@media (min-width: 1025px) {
  h1,
  .h1 {
    font-size: 60px;
  }

  h2,
  .h2 {
    font-size: 42px;
    line-height: 1.14em;
    padding: 0 0 15px;
  }

  h3,
  .h3 {
    font-size: 32px;
    line-height: 1.18em;
  }

  h4,
  .h4 {
    font-size: 30px;
  }

  h5,
  .h5 {
    font-size: 20px;
  }

  h6,
  .h6 {
    font-size: 18px;
  }
}

p,
ul,
ol {
  font-size: 16px;
  line-height: 1.625em;
  margin: 0;
  padding: 0 0 12px;
  color: #666;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

p:last-child,
ul:last-child,
ol:last-child {
  padding: 0;
}

ul,
ol {
  width: auto;
  overflow: hidden;
  list-style: none;
}

ul li,
ol li {
  position: relative;
  padding-left: 18px;
  margin-bottom: 0;
}

a {
  word-break: break-word;
  color: #1c1c1c;
  font-weight: 600;
  text-decoration: none;
  text-decoration: none;
}

a:hover {
  color: #fc0;
  text-decoration: none;
}

@media (min-width: 768px) {
  p,
  ul,
  ol {
    font-size: 17px;
    line-height: 1.58em;
  }

  ul,
  ol {
    padding-left: 16px;
  }

  ul li,
  ol li {
    padding-left: 20px;
  }
}

@media (min-width: 1025px) {
  p,
  ul,
  ol {
    font-size: 18px;
    line-height: 1.55em;
    padding: 0 0 15px;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul li,
  ol li {
    padding-left: 25px;
  }

  ul li:before {
    width: 8px;
    height: 8px;
    top: 10px;
  }
}

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container {
  max-width: 1366px;
}

hr {
  background-color: #ccc;
  height: 1px;
  border: 0;
  margin: 30px 0;
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}

[class*="col-"] {
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

[class*="col-xxs-"] {
  float: left;
}

.col-xxs-12 {
  width: 100%;
}

.col-xxs-11 {
  width: 91.66666667%;
}

.col-xxs-10 {
  width: 83.33333333%;
}

.col-xxs-9 {
  width: 75%;
}

.col-xxs-8 {
  width: 66.66666667%;
}

.col-xxs-7 {
  width: 58.33333333%;
}

.col-xxs-6 {
  width: 50%;
}

.col-xxs-5 {
  width: 41.66666667%;
}

.col-xxs-4 {
  width: 33.33333333%;
}

.col-xxs-3 {
  width: 25%;
}

.col-xxs-2 {
  width: 16.66666667%;
}

.col-xxs-1 {
  width: 8.33333333%;
}

.col-xxs-pull-12 {
  right: 100%;
}

.col-xxs-pull-11 {
  right: 91.66666667%;
}

.col-xxs-pull-10 {
  right: 83.33333333%;
}

.col-xxs-pull-9 {
  right: 75%;
}

.col-xxs-pull-8 {
  right: 66.66666667%;
}

.col-xxs-pull-7 {
  right: 58.33333333%;
}

.col-xxs-pull-6 {
  right: 50%;
}

.col-xxs-pull-5 {
  right: 41.66666667%;
}

.col-xxs-pull-4 {
  right: 33.33333333%;
}

.col-xxs-pull-3 {
  right: 25%;
}

.col-xxs-pull-2 {
  right: 16.66666667%;
}

.col-xxs-pull-1 {
  right: 8.33333333%;
}

.col-xxs-pull-0 {
  right: auto;
}

.col-xxs-push-12 {
  left: 100%;
}

.col-xxs-push-11 {
  left: 91.66666667%;
}

.col-xxs-push-10 {
  left: 83.33333333%;
}

.col-xxs-push-9 {
  left: 75%;
}

.col-xxs-push-8 {
  left: 66.66666667%;
}

.col-xxs-push-7 {
  left: 58.33333333%;
}

.col-xxs-push-6 {
  left: 50%;
}

.col-xxs-push-5 {
  left: 41.66666667%;
}

.col-xxs-push-4 {
  left: 33.33333333%;
}

.col-xxs-push-3 {
  left: 25%;
}

.col-xxs-push-2 {
  left: 16.66666667%;
}

.col-xxs-push-1 {
  left: 8.33333333%;
}

.col-xxs-push-0 {
  left: auto;
}

.col-xxs-offset-12 {
  margin-left: 100%;
}

.col-xxs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xxs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xxs-offset-9 {
  margin-left: 75%;
}

.col-xxs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xxs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xxs-offset-6 {
  margin-left: 50%;
}

.col-xxs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xxs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xxs-offset-3 {
  margin-left: 25%;
}

.col-xxs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xxs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xxs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 600px) {
  [class*="col-xs-"] {
    float: left;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-11 {
    width: 91.66666667%;
  }

  .col-xs-10 {
    width: 83.33333333%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-8 {
    width: 66.66666667%;
  }

  .col-xs-7 {
    width: 58.33333333%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-5 {
    width: 41.66666667%;
  }

  .col-xs-4 {
    width: 33.33333333%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-2 {
    width: 16.66666667%;
  }

  .col-xs-1 {
    width: 8.33333333%;
  }

  .col-xs-pull-12 {
    right: 100%;
  }

  .col-xs-pull-11 {
    right: 91.66666667%;
  }

  .col-xs-pull-10 {
    right: 83.33333333%;
  }

  .col-xs-pull-9 {
    right: 75%;
  }

  .col-xs-pull-8 {
    right: 66.66666667%;
  }

  .col-xs-pull-7 {
    right: 58.33333333%;
  }

  .col-xs-pull-6 {
    right: 50%;
  }

  .col-xs-pull-5 {
    right: 41.66666667%;
  }

  .col-xs-pull-4 {
    right: 33.33333333%;
  }

  .col-xs-pull-3 {
    right: 25%;
  }

  .col-xs-pull-2 {
    right: 16.66666667%;
  }

  .col-xs-pull-1 {
    right: 8.33333333%;
  }

  .col-xs-pull-0 {
    right: auto;
  }

  .col-xs-push-12 {
    left: 100%;
  }

  .col-xs-push-11 {
    left: 91.66666667%;
  }

  .col-xs-push-10 {
    left: 83.33333333%;
  }

  .col-xs-push-9 {
    left: 75%;
  }

  .col-xs-push-8 {
    left: 66.66666667%;
  }

  .col-xs-push-7 {
    left: 58.33333333%;
  }

  .col-xs-push-6 {
    left: 50%;
  }

  .col-xs-push-5 {
    left: 41.66666667%;
  }

  .col-xs-push-4 {
    left: 33.33333333%;
  }

  .col-xs-push-3 {
    left: 25%;
  }

  .col-xs-push-2 {
    left: 16.66666667%;
  }

  .col-xs-push-1 {
    left: 8.33333333%;
  }

  .col-xs-push-0 {
    left: auto;
  }

  .col-xs-offset-12 {
    margin-left: 100%;
  }

  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xs-offset-9 {
    margin-left: 75%;
  }

  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xs-offset-6 {
    margin-left: 50%;
  }

  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xs-offset-3 {
    margin-left: 25%;
  }

  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xs-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  [class*="col-sm-"] {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1025px) {
  [class*="col-md-"] {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  [class*="col-lg-"] {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.button {
  /* font-size: 14px; */
  line-height: 1.166em;
  font-weight: 700;
  display: inline-block;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 14px 12px 14px 20px;
  position: relative;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 0.01em;
  font-family: "Source Sans Pro", sans-serif;
  transition: all 0.4s ease-in-out;
}

.button:hover {
  color: #fff;
  text-decoration: none;
}

.button--primary,
.button--secondary {
  margin-right: 16px;
}

.button--primary,
.button--secondary,
.button--tertiary {
  padding: 0.625em 1em;
  position: relative;
}

.button--primary {
  background: #fc0;
  color: #1c1c1c;
}

.button--primary:after {
  background: #fc0;
}

.button--primary:hover,
.button--primary:hover:after {
  background: #666;
}

.button--primary:hover {
  color: #fff;
}

.button--secondary {
  background: #666;
  color: #fff;
}

.button--secondary:after {
  background: #666;
}

.button--secondary:hover {
  background: #fc0;
  color: #1c1c1c;
}

.button--secondary:hover:after {
  background: #fc0;
}

.button--tertiary {
  background: #202020;
  color: #fff;
}

.button--tertiary:after {
  border-color: transparent transparent transparent #202020;
}

.button--tertiary:hover {
  background: #444;
  color: #fff;
}

.button--tertiary:hover:after {
  border-color: transparent transparent transparent #444;
}

.button--primary::after,
.button--secondary::after {
  -webkit-clip-path: polygon(4px 0, 100% 50%, 4px 100%, 0% 100%, 0% 0%);
  clip-path: polygon(4px 0, 100% 50%, 4px 100%, 0% 100%, 0% 0%);
}

/* 
.button--primary:after,.button--secondary:after,.button--tertiary:after {
    content: "";
    position: absolute;
    top: 0;
    right: -17px;
    width: 18px;
    height: 100%;
    -webkit-clip-path: polygon(1px 0,100% 50%,1px 100%,0% 100%,0% 0%);
    clip-path: polygon(1px 0,100% 50%,1px 100%,0% 100%,0% 0%);
    transition: all .4s ease-in-out
} */

.button--block {
  display: block;
  height: 48px;
  width: 100%;
}

@media (min-width: 400px) {
  .button--primary,
  .button--secondary,
  .button--tertiary {
    text-transform: uppercase;
  }
}

@media (min-width: 600px) {
  .button {
    padding: 14px 16px 14px 24px;
  }
}

@media (min-width: 1025px) {
  .button {
    padding: 15px 26px 15px 30px;
  }
}

@media (min-width: 1200px) {
  .button--block {
    width: 100%;
  }
}

.btn {
  font-size: 16px;
  line-height: 1.166em;
  font-weight: 700;
  display: inline-block;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 14px 12px 14px 20px;
  position: relative;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 0.01em;
  font-family: "Source Sans Pro", sans-serif;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  text-decoration: none;
}

.btn-primary,
.btn-secondary {
  margin-right: 16px;
}

/* 
.btn-primary::after,.btn-secondary::after {
    content: "";
    position: absolute;
    top: 0;
    right: -17px;
    width: 18px;
    height: 100%;
    -webkit-clip-path: polygon(1px 0,100% 50%,1px 100%,0% 100%,0% 0%);
    clip-path: polygon(1px 0,100% 50%,1px 100%,0% 100%,0% 0%);
    transition: all .4s ease-in-out
}

.btn-primary::after {
	background: #FC0
} */

.btn-primary:hover::after {
  background: #666;
}

.btn-secondary:hover::after {
  background: #fc0;
}

.btn-secondary::after {
  background: #666;
}

.btn-primary {
  background: #fc0;
  color: #1c1c1c;
  text-align: center;
}

.btn-primary:hover {
  background: #666;
  color: #fff;
}

.btn-secondary {
  background: #666;
  color: #fff;
}

.btn-secondary:hover {
  background: #fc0;
  color: #1c1c1c;
}

.btn-tertiary {
  padding: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn + .btn {
  display: table;
  margin-top: 20px;
}

@media (min-width: 600px) {
  .btn + .btn {
    margin-top: 0;
    margin-left: 22px;
    display: inline-block;
  }

  .btn + .btn + .btn {
    margin-top: 28px;
  }

  .btn {
    width: auto;
    font-size: 17px;
    padding: 14px 16px 14px 24px;
  }
}

@media (min-width: 1025px) {
  .btn {
    font-size: 16px;
    padding: 15px 26px 15px 30px;
  }

  .btn + .btn {
    margin-left: 28px;
  }

  .btn + .btn + .btn {
    margin-top: 0;
  }

  .btn-primary:hover::after,
  .btn-secondary:hover::after {
    -webkit-clip-path: polygon(4px 0, 100% 50%, 4px 100%, 0% 100%, 0% 0%);
    clip-path: polygon(4px 0, 100% 50%, 4px 100%, 0% 100%, 0% 0%);
  }
}

.heading-title {
  background: #fc0;
  color: #000;
  display: block;
  margin-bottom: 0;
  padding: 0.55em;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.heading-title.black,
.page-sidebar .widget.black .heading-title {
  background: #222;
  color: #fff;
}

.heading-title.blue {
  background: #353a45;
  color: #fff;
}

.heading-title.blue span {
  color: #727987;
}

.heading-title.gray {
  background: #f2f2f2;
  color: #1c1c1c;
}

.heading-title.accent:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 31px 0 31px;
  border-color: #fc0 transparent transparent transparent;
  position: absolute;
  bottom: -15px;
  margin-left: -31px;
  left: 50%;
}

.heading-title.black.accent:after,
.page-sidebar .widget.black .heading-title:after {
  border-color: #222 transparent transparent transparent;
}

.heading-title.blue.accent:after {
  border-color: #353a45 transparent transparent transparent;
}

.heading-title.gray.accent:after {
  border-color: #f2f2f2 transparent transparent transparent;
}

.widget_search .heading-title,
.widget_categories .heading-title,
.widget_archive .heading-title {
  background: #474d59;
  color: #fff;
}

.widget_search .heading-title.accent:after,
.widget_categories .heading-title.accent:after,
.widget_archive .heading-title.accent:after {
  border-color: #474d59 transparent transparent;
}

@font-face {
  font-display: swap;
  font-family: "icomoon";
  src: url(/fonts/icons/icomoon.eot?az24iy);
  src: url("/fonts/icons/icomoon.eot?#iefixaz24iy") format("embedded-opentype"),
    url(/fonts/icons/icomoon.ttf?az24iy) format("truetype"), url(/fonts/icons/icomoon.woff?az24iy) format("woff"),
    url("/fonts/icons/icomoon.svg?az24iy#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"]:after,
[class*=" icon-"]:after,
.nav--top-menu li:first-child a:after,
.slick-prev:before,
.slick-next:before {
  font-family: "icomoon";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e60e";
}

.icon-camera:before {
  content: "\e60d";
}

.icon-360:before {
  content: "\e60f";
}

.icon-login:before {
  content: "\e60c";
}

.icon-close:before {
  content: "\e60b";
}

.icon-menu:before {
  content: "\e600";
}

.icon-search:before {
  content: "\e601";
}

.icon-chat:before {
  content: "\e603";
}

.icon-map-pin-alt:before {
  content: "\e604";
}

.icon-google-plus:before {
  content: "\e605";
}

.icon-instagram:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e606";
}

.icon-twitter:before {
  content: "\e607";
}

.icon-youtube3:before {
  content: "\e608";
}

.icon-linkedin2:before {
  content: "\e609";
}

.icon-mobile2:before {
  content: "\e60a";
}

.icon-mail:before {
  content: "\f03b";
}

.icon-chevron-right:before {
  content: "\f078";
}

.icon-chevron-up:before {
  content: "\f0a2";
}

.icon-chevron-down:before {
  content: "\f0a3";
}

.icon-chevron-left:before {
  content: "\f0a4";
}

.flexbox {
  display: table;
  width: 100%;
}

html.flexbox {
  display: block;
  width: auto;
}

.flexbox__item {
  display: table-cell;
  vertical-align: middle;
}

.block-list,
.matrix,
.block-list > li,
.matrix > li {
  border: 0 solid #ccc;
}

.block-list,
.matrix {
  list-style: none;
  margin-left: 0;
  border-top-width: 1px;
}

.block-list > li,
.matrix > li {
  border-bottom-width: 1px;
  padding: 12px;
}

.block-list__link,
.matrix__link {
  display: block;
  padding: 12px;
  margin: -12px;
}

.matrix {
  border-left-width: 1px;
}

.matrix > li {
  float: left;
  border-right-width: 1px;
}

.multi-list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 1em;
}

.multi-list > li {
  float: left;
}

.multi-list--padded > li {
  padding-left: 10px;
  padding-right: 10px;
}

.two-cols > li {
  width: 50%;
}

.three-cols > li {
  width: 33.333%;
}

.four-cols > li {
  width: 25%;
}

.five-cols > li {
  width: 20%;
}

.matrix > .all-cols,
.multi-list > .all-cols {
  width: 100%;
}

@media (max-width: 768px) {
  .two-cols > li {
    width: 100%;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

/* .slick-slide > div {
  height: 470px;
} */

.equipment-detail > div {
  height: 500px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.sc-slideshow .slick-initialized .slick-slide {
  padding-left: 0;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "\f0a4";
}

[dir="rtl"] .slick-prev:before {
  content: "\f0a4";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "\f078";
}

[dir="rtl"] .slick-next:before {
  content: "\f078";
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: none !important;
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  display: none !important;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: " ";
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

.js-slider-has-preloader {
  min-height: 50px;
  position: relative;
}

/* .js-slider-has-preloader:before {
    content: url(../../../../../../../../themes/thompsonmachineryhttps://d3leeb4r1qy96s.cloudfront.net/assets/img/loading.gif);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transition: all .5s ease;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
} */

.js-slider-has-preloader-init:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.your-slider .slide {
  display: none;
}

.your-slider .slide:first-child {
  display: block;
}

.your-slider.slick-initialized .slide {
  display: block;
}

.slick-arrow {
  position: absolute;
  bottom: 0;
  z-index: 99;
  border: none;
  padding: 0;
  font-size: 0;
  width: 64px;
  height: 40px;
  color: #272727;
  background: transparent;
  transition: all 0.4s ease-in-out;
  -webkit-filter: drop-shadow(0 0 15px rgba(88, 71, 0, 0.1));
  filter: drop-shadow(0 0 15px rgba(88, 71, 0, 0.1));
}

.slick-arrow:hover {
  color: #fc0;
}

.slick-arrow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fc0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
}

.slick-arrow:hover::before {
  background: #272727;
}

.slick-prev::before {
  -webkit-clip-path: polygon(100% 0, 52px 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 52px 100%, 0 100%, 0 0);
}

.slick-next::before {
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 14px 0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 14px 0);
}

.slick-arrow::after {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slick-prev::after {
  border-right-color: #272727;
  border-left: none;
  left: 22px;
}

.slick-prev:hover:after {
  border-right-color: #ffcd11;
}

.slick-next::after {
  border-left-color: #272727;
  border-right: none;
  left: 30px;
}

.slick-next:hover:after {
  border-left-color: #ffcd11;
}

.slick-prev {
  left: 50%;
  margin-left: -62px;
}

.slick-next {
  right: 50%;
  margin-right: -62px;
}

.slick-dots {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slick-dots li::after,
.slick-dots li::before {
  display: none;
}

.slick-dots li {
  padding: 0;
  margin: 0 2px 0 0;
  width: auto;
  height: auto;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots li button {
  background: rgba(102, 102, 102, 0.4);
  font-size: 0;
  padding: 0;
  border: none;
  width: 40px;
  height: 7px;
  -webkit-clip-path: polygon(4px 0, 100% 0, 90% 100%, 0 100%);
  clip-path: polygon(4px 0, 100% 0, 90% 100%, 0 100%);
  transition: all 0.4s ease-in-out;
}

.slick-dots li:last-child button {
  -webkit-clip-path: polygon(4px 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(4px 0, 100% 0, 100% 100%, 0 100%);
}

.slick-dots li:first-child button {
  -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.slick-dots li.slick-active button {
  background: #fc0;
}

@media (min-width: 768px) {
  .slick-arrow {
    width: 73px;
    height: 46px;
  }

  .slick-arrow::after {
    font-size: 19px;
  }

  .slick-prev::after {
    left: 25px;
  }

  .slick-next::after {
    left: 34px;
  }

  .slick-next {
    right: 0;
    margin-right: 0;
  }

  .slick-prev {
    left: unset;
    right: 67px;
    margin-left: 0;
  }

  .slick-prev::before {
    -webkit-clip-path: polygon(100% 0, 60px 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 60px 100%, 0 100%, 0 0);
  }
}

@media (min-width: 1025px) {
  .slick-arrow {
    width: 80px;
    height: 50px;
  }

  .slick-arrow::after {
    font-size: 22px;
  }

  .slick-next::after {
    left: 36px;
  }

  .slick-prev::before {
    -webkit-clip-path: polygon(100% 0, 64px 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 64px 100%, 0 100%, 0 0);
  }

  .slick-next::before {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 16px 0);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 16px 0);
  }

  .slick-prev {
    right: 73px;
  }
}

table {
  border-collapse: collapse;
}

.tablepress tfoot th,
.tablepress thead th {
  background-color: #474d59 !important;
  text-transform: uppercase;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #fff;
  border-bottom: 3px solid #ffcd11 !important;
}

.tablepress td,
.tablepress th {
  padding: 10px 25px !important;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

label,
legend {
  color: #575757;
  display: block;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
}

legend {
  margin-bottom: 20px;
  padding: 0;
}

button {
  border: none;
  outline: none;
}

input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="week"],
textarea,
select {
  border: 1px solid #bababa;
  color: gray;
  font-size: 14px;
  padding: 0.714em;
  width: 100%;
}

input[type="search"] {
  box-sizing: border-box;
}

.wpcf7-form input[type="text"]:hover,
.wpcf7-form input[type="date"]:hover,
.wpcf7-form input[type="datetime"]:hover,
.wpcf7-form input[type="datetime-local"]:hover,
.wpcf7-form input[type="email"]:hover,
.wpcf7-form input[type="month"]:hover,
.wpcf7-form input[type="number"]:hover,
.wpcf7-form input[type="password"]:hover,
.wpcf7-form input[type="search"]:hover,
.wpcf7-form input[type="tel"]:hover,
.wpcf7-form input[type="url"]:hover,
.wpcf7-form input[type="week"]:hover,
.wpcf7-form textarea:hover,
.wpcf7-form select:hover,
.wpcf7-form input[type="text"]:focus,
.wpcf7-form input[type="date"]:focus,
.wpcf7-form input[type="datetime"]:focus,
.wpcf7-form input[type="datetime-local"]:focus,
.wpcf7-form input[type="email"]:focus,
.wpcf7-form input[type="month"]:focus,
.wpcf7-form input[type="number"]:focus,
.wpcf7-form input[type="password"]:focus,
.wpcf7-form input[type="search"]:focus,
.wpcf7-form input[type="tel"]:focus,
.wpcf7-form input[type="url"]:focus,
.wpcf7-form input[type="week"]:focus,
.wpcf7-form textarea:focus,
.wpcf7-form select:focus {
  border: 1px solid #fc0;
}

.wpcf7-form textarea.textarea-short {
  height: 100px;
}

input[type="submit"],
button[type="submit"] {
  border: none;
}

.form-row {
  margin-bottom: 1.25em;
}

.one-line-input {
  position: relative;
}

.one-line-input input {
  padding-right: 40px;
}

.one-line-input button {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 0;
  padding: 0;
  text-align: center;
  height: 40px;
  width: 40px;
}

.one-line-input button:after {
  display: none;
}

.one-line-input--text-button button {
  padding: 0.4em 1.063em;
  position: relative;
  right: auto;
  top: auto;
  text-align: left;
  width: 100%;
}

.filter-options input[type="checkbox"] {
  margin-right: 5px;
}

.filter-options label {
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: none;
}

@media (min-width: 600px) {
  .one-line-input--text-button input {
    display: inline-block;
    padding-right: 0.714em;
    width: 60%;
  }

  .one-line-input--text-button .button {
    display: inline-block;
    margin-right: 5%;
    padding: 0.4em 1.063em;
    position: relative;
    right: auto;
    top: auto;
    text-align: left;
    width: 35%;
  }
}

.form-box,
.page-sidebar .textwidget {
  background: #f2f2f2;
  padding: 30px;
}

.page-sidebar .textwidget .sidebar-events {
  padding: 30px 30px 5px 30px;
}

.form-box .selectric,
.form-box input,
.form-box textarea {
  background: #f2f2f2;
}

.form-box .selectric-open .selectric {
  background: #fff;
}

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  color: red;
}

.wpcf7-response-output {
  color: red;
  font-weight: 700;
}

select.wpcf7-not-valid,
textarea.wpcf7-not-valid,
input.wpcf7-not-valid {
  border: 1px solid red;
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #bababa;
  position: relative;
  border-radius: 4px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 14px;
  line-height: 38px;
  color: gray;
  height: 38px;
  min-width: 168px;
}

.selectric .button {
  background: none;
  border-left: 1px solid #bababa;
  display: block;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  color: #bbb;
  text-align: center;
  font: 0/0 a;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #bbb;
  border-bottom: none;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}

.selectric-hover .selectric .button {
  color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c4c4;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
  display: none;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  /* *font: 0/0 a!important; */
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #f8f8f8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 8px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #eee;
  color: #666;
  cursor: pointer;
}

.selectric option {
  display: block;
  padding: 8px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #eee;
  color: #666;
  cursor: pointer;
}

.selectric-items li:before {
  content: "";
  display: none;
}

.selectric-items li.selected {
  background: #fff;
  color: #444;
}

.selectric-items li:hover {
  background: #f0f0f0;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: 700;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

.page-template-page-forestry.has-mtsnb .page-header {
  margin-top: 11px;
}

.mtsnb .mtsnb-container {
  padding-top: 7px;
  padding-bottom: 7px;
}

.page-header {
  background: #fff;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  z-index: 100;
}

.page-header--search,
.nav--top-menu,
.page-header--phone {
  display: inline-block;
}

.page-header--phone {
  color: #000;
  background-color: #ffcd11;
}

.page-header--phone.button:hover {
  background: #353a45;
  color: #fff;
}

.page-header--search {
  margin-top: 0;
}

.page-header__bottom-row {
  padding-top: 20px;
  padding-bottom: 20px;
}

.site-logo {
  display: block;
  height: auto;
  max-width: 100%;
}

.search-form {
  position: relative;
  width: 274px;
}

.search-form .search-form--field {
  border: none;
  border-bottom: 1px solid #e2e2e2;
  color: #666;
  padding: 0.429em 0;
  position: relative;
}

.search-form .search-form--field:focus-visible {
  outline: none;
}

.search-form .search-form--field:focus {
  border-color: #ffcd11;
}

.search-form .search-form--submit {
  background: url("https://d3leeb4r1qy96s.cloudfront.net/assets/img/icon-search.png") no-repeat center;
  height: 16px;
  width: 16px;
  padding-right: 0;
  position: absolute;
  right: 8px;
  top: 10%;
}

.search-form .search-form--submit:hover {
  background: url("https://d3leeb4r1qy96s.cloudfront.net/assets/img/icon-search.png") no-repeat center;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}

.mobile-search-form {
  background: #474d58;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  width: 100%;
}

.mobile-search-form.js-visible {
  height: 70px;
  overflow: visible;
}

.mobile-search-form .search-form {
  margin: 20px 5%;
  width: 90%;
}

.mobile-search-form .search-form .search-form--field {
  padding: 5px;
}

.mobile-search-form .search-form .search-form--submit {
  right: 0;
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .search-form {
    width: 194px;
  }
}

.phone-number {
  font-size: 14px;
  font-weight: 700;
  padding: 8px 16px;
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .phone-number {
    padding: 0.286em 2px;
  }
}

.button.page-header--phone.phone-number.trucks-page-btn {
  display: none;
}

.page-id-14748 .button.page-header--phone.phone-number,
.page-id-15221 .button.page-header--phone.phone-number,
.term-745 .button.page-header--phone.phone-number {
  display: none;
}

.page-id-14748 .button.page-header--phone.phone-number.trucks-page-btn,
.page-id-15221 .button.page-header--phone.phone-number.trucks-page-btn,
.term-745 .button.page-header--phone.phone-number.trucks-page-btn {
  display: inline-block !important;
}

.header-login {
  display: inline-block;
  position: relative;
}

.header-login__trigger {
  background-color: #fff;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: #666;
  border: 1px solid #e2e2e2;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  z-index: 2;
  font-family: "Source Sans Pro", sans-serif;
  transition: all 0.25s ease-out;
}

.header-login__trigger:hover {
  background: #353a45;
  border-color: #353a45;
  color: #fff;
}

.header-login:hover .header-login__trigger {
  border-bottom: 0;
}

.header-login:hover .header-login__form {
  display: block;
}

.header-login__form {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  margin-top: -1px;
  padding: 20px 20px 5px 20px;
  min-width: 400px;
}

.header-login__form .form-field {
  margin-bottom: 10px;
}

.header-login__form .cat-portlet-small-bottom-margin {
  position: absolute;
  right: 0;
  top: 10px;
}

@media (min-width: 768px) {
  .header-login {
    margin-right: 10px;
  }
}

@media (min-width: 1025px) {
  .header-login {
    margin-left: 5px;
    margin-right: 0;
  }

  /* .header-top-right-section {
    margin-top: 9px;
  } */

  .header-right-top {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: right;
  }

  /* .page-header.active .header-top-right-section {
    margin-top: 3px;
  } */

  .header-login {
    margin-bottom: 1px;
  }

  .header-login__trigger {
    padding: 5px;
  }
}

@media (min-width: 1200px) {
  .header-right-top {
    justify-content: flex-end;
  }
}

@media (min-width: 1366px) {
  .header-login {
    margin-left: 20px;
  }
}

.page-header__mobile-buttons {
  border-bottom: 1px solid #e2e2e2;
}

.page-header__mobile-button {
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  width: 50%;
}

.page-header__mobile-button:first-child {
  border-right: 1px solid #e2e2e2;
}

.page-header__mobile-button span {
  font-size: 16px;
  vertical-align: middle;
}

.page-id-14748 .page-header__mobile-button.mobile--phone-btn,
.page-id-15221 .page-header__mobile-button.mobile--phone-btn,
.term-745 .page-header__mobile-button.mobile--phone-btn {
  display: none;
}

.page-id-14748 .page-header__mobile-button.trucks-page-btn--mobile,
.page-id-15221 .page-header__mobile-button.trucks-page-btn--mobile,
.term-745 .page-header__mobile-button.trucks-page-btn--mobile {
  display: block;
}

.mobile-trigger-button {
  border: none;
  background: none;
  color: #666;
  outline: none;
  margin: 0;
  padding: 0;
}

.mobile-trigger-button.js-active {
  color: #000;
}

.mobile-trigger-button.js-active [class^="icon-"]:before {
  content: "\e60b";
}

.mobile-trigger-button--search {
  font-size: 26px;
}

.mobile-trigger-button--menu {
  font-size: 36px;
  margin-left: 10px;
}

.mobile-trigger-button--menu:after {
  content: "Menu";
  display: block;
  font-size: 10px;
  font-weight: 900;
  line-height: 10px;
  text-transform: uppercase;
}

@media (min-width: 600px) {
  .page-header__bottom-row {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .page-header__mobile-button {
    display: inline-block;
    font-size: 12px;
    float: none;
    padding: 0;
    width: auto;
  }

  .page-header__mobile-button span {
    font-size: 14px;
  }

  .page-header--search,
  .page-header__bottom-row .page-header__mobile-button {
    margin-right: 15px;
  }

  .phone-number {
    margin-right: 20px;
  }
}

@media (min-width: 1025px) {
  .page-header {
    position: absolute;
    top: 0;
  }

  .page-header.active {
    position: fixed;
    top: 0;
  }

  .has-mtsnb .page-header {
    top: 36px !important;
  }

  .page-header__bottom-row {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .page-header--search,
  .nav--top-menu,
  .page-header--phone {
    margin-left: 0.55em;
    margin-top: 0.625em;
    margin-right: 0;
  }

  .sticky-page-header.active .page-header__bottom-row {
    padding-top: 10px;
  }

  .sticky-page-header.active .site-logo {
    max-width: 90% !important;
    min-width: 90% !important;
    min-height: 90% !important;
    max-height: 90% !important;
  }

  .sticky-page-header.active .nav--primary .menu > li {
    padding: 10px 15px 10px;
  }

  .sticky-page-header.active .nav--primary a {
    font-size: 16px;
  }
}

.nav--top-menu {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav--top-menu li {
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 0;
}

.nav--top-menu li a {
  font-size: 12px;
  color: #666;
  font-weight: 700;
}

.nav--top-menu li span {
  font-size: 12px;
  color: #666;
  font-weight: 700;
}

.nav--top-menu li a:hover {
  color: #fc0;
}

.nav--top-menu li:before {
  content: "";
  display: none;
}

/* 
.nav--top-menu li:first-child {
	padding: 0
} */

.nav--top-menu li:last-child {
  padding-right: 0;
}

/* @media (min-width: 1366px) {
  .nav--top-menu li {
    padding: 0 1em;
  }
} */

.nav--primary {
  border-top: 1px solid #e2e2e2;
  display: none;
  margin-top: 15px;
}

.nav--primary ul {
  overflow: visible;
}

.nav--primary.js-visible {
  display: block;
}

.nav--primary li {
  border-top: 1px solid #e2e2e2;
  position: relative;
  margin-bottom: 0;
}

.nav--primary li:before {
  content: "";
  display: none;
}

.nav--primary > ul > li:first-child {
  border: none;
}

.nav--primary a {
  color: #2c2c2c;
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  text-transform: uppercase;
}

.sub-menu-toggle {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 20px;
  font-size: 24px;
  border-left: 1px solid #e2e2e2;
  color: #999;
  transition: all 0.3s ease;
}

.sub-menu-toggle.toggled {
  color: #000;
}

.sub-menu .sub-menu-toggle {
  padding: 4px 20px;
}

.menu-item-32 > .sub-menu {
  width: 540px;
}

.menu-item-32 > .sub-menu > li {
  float: left;
  width: 50%;
}

@media (min-width: 1025px) {
  .nav--primary {
    display: block;
    text-align: center;
  }

  .nav--primary li {
    border-top: none;
    display: inline-block;
    text-align: center;
    padding: 22px 15px 24px;
  }

  .nav--primary a {
    display: inline-block;
    padding: 0;
  }

  .nav--primary > ul > li > a {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .nav--primary li:hover > a,
  .nav--primary .menu > li.current-menu-item > a,
  .nav--primary .menu > li.current-page-parent > a,
  .nav--primary .menu > li.current-page-ancestor > a {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
  }
}

@media (min-width: 1200px) {
  .nav--primary li {
    padding: 22px 1.25em 24px;
  }
}

.sub-menu {
  background: #f7f7f7;
  display: none;
}

.sub-menu.toggled {
  display: block;
}

.sub-menu a {
  font-size: 16px;
  font-weight: 400;
  display: block;
  padding: 0.25em 0.75em;
  word-break: normal;
}

.sub-menu .sub-menu {
  background: #fff;
}

@media (min-width: 1025px) {
  .sub-menu {
    background: #fff;
    border-bottom: 4px solid #474d59;
    box-shadow: 12px 0 15px -4px rgba(0, 0, 0, 0.2), -12px 0 8px -4px rgba(0, 0, 0, 0.2), 0 5px 16px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0;
    padding: 1.25em 0.75em;
    position: absolute;
    top: 100%;
    width: 270px;
    z-index: 9999;
  }

  .sub-menu li {
    display: block;
    padding: 0;
    text-align: left;
  }

  .sub-menu a {
    font-size: 16px;
    font-weight: 400;
    display: block;
    padding: 0.25em 0.75em;
    text-transform: capitalize;
  }

  .sub-menu li:hover > a,
  .sub-menu .sub-menu li:hover > a,
  .sub-menu li.current-menu-item > a,
  .sub-menu li.current-page-item > a,
  .sub-menu li.current_page_item > a {
    background: #f2f2f2;
    border: none;
  }

  .nav--primary li:hover > .sub-menu {
    display: block;
  }

  .sub-menu .sub-menu {
    left: 244px;
    margin-top: 0;
    top: -30px;
  }

  .menu-item-339 > .sub-menu {
    width: 540px;
  }

  .menu-item-339 > .sub-menu > li {
    width: 50%;
    float: left;
  }

  .sub-menu .sub-menu a {
    font-size: 13px;
  }
}

.masthead,
.masthead-item,
.masthead-overlay {
  position: relative;
}

.masthead {
  background: #272727;
  padding: 0 0 25px;
}

.js-masthead-homepage-slider {
  max-height: 500px;
  overflow: hidden;
  margin-bottom: 0;
}

.js-masthead-homepage-slider ul {
  display: flex !important;
}

.masthead-image {
  width: 100%;
  height: 225px;
  -webkit-clip-path: polygon(100% 0, 100% 186px, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 186px, 0 100%, 0 0);
}

.masthead-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.masthead-overlay {
  padding: 28px 0 0;
  z-index: 9;
}

.js-masthead-homepage-slider .slick-list {
  padding: 0 0 38px;
}

.masthead-content h3 {
  color: #fc0;
  font-size: 22px;
  padding: 0 0 4px;
}

.masthead-content h1,
.masthead-content .h1 {
  color: #fff;
  padding: 0 0 15px;
  font-size: 42px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
}

.masthead .btn {
  margin-bottom: 45px;
}

.js-masthead-homepage-slider .slick-dots {
  justify-content: flex-start;
  padding: 0 22px;
}

.js-masthead-homepage-slider .slick-dots li {
  margin-bottom: 7px;
}

.js-masthead-homepage-slider .slick-dots li button {
  background: rgba(255, 255, 255, 0.4);
}

.js-masthead-homepage-slider .slick-dots li button:before {
  content: "";
}

.js-masthead-homepage-slider .slick-dots li.slick-active button {
  background: #fff;
}

@media (min-width: 768px) {
  .masthead {
    padding: 0;
    background: transparent;
    z-index: 9;
  }

  .masthead-image {
    height: 540px;
    -webkit-clip-path: unset;
    clip-path: unset;
  }

  .masthead-item {
    -webkit-clip-path: polygon(100% 0, 100% 460px, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 460px, 0 100%, 0 0);
  }

  .js-masthead-homepage-slider .slick-slide:before {
    content: " ";
    /* background: linear-gradient(92.06deg,rgba(0,0,0,.6) 3.32%,rgba(0,0,0,.502) 39.95%,rgba(0,0,0,0) 99.97%); */
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .masthead-overlay {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    padding: 0;
  }

  .js-masthead-homepage-slider .slick-list {
    padding: 0 0 0;
  }

  .js-masthead-homepage-slider .slick-dots {
    bottom: 70px;
  }

  .masthead-content h3 {
    font-size: 26px;
  }

  .masthead-content h1,
  .masthead-content .h1 {
    font-size: 56px;
  }
}

@media (min-width: 1025px) {
  .js-masthead-homepage-slider {
    max-height: 500px;
  }

  .masthead {
    padding-top: 150px;
  }

  .page-template-default.page.logged-in .masthead,
  .blog.logged-in,
  .masthead,
  .page-template-default.home.page.logged-in .masthead {
    padding: 117px 0 0;
  }

  .js-masthead-homepage-slider .slick-dots {
    width: 100%;
    max-width: 1366px;
    padding: 0 20px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 160px;
  }

  .masthead-image {
    height: 750px;
  }

  .masthead-item {
    -webkit-clip-path: polygon(100% 0, 100% 600px, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 600px, 0 100%, 0 0);
  }

  .masthead-content h3 {
    font-size: 32px;
    padding: 0 0 2px;
  }

  .masthead-content h1,
  .masthead-content .h1 {
    font-size: 72px;
    line-height: 0.934em;
    padding: 0 0 32px;
  }

  .masthead-content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .masthead .container {
    padding: 0 22px;
  }
}

@media (min-width: 1200px) {
  .page-template-default.page.logged-in .masthead,
  .blog.logged-in,
  .masthead,
  .page-template-default.home.page.logged-in .masthead {
    padding: 154px 0 0;
  }

  .page-template-default.home.page .masthead {
    padding: 154px 0 0;
  }

  .page-template-default.page .masthead {
    padding: 154px 0 0;
  }

  .masthead-overlay {
    top: 35%;
  }

  .js-masthead-homepage-slider .slick-dots {
    bottom: 190px;
  }
}

.masthead-inner {
  position: relative;
}

.masthead-inner-banner,
.masthead-inner-left-shape,
.masthead-inner-right-shape {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.masthead-inner-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
  -o-object-position: -136px;
  object-position: -136px;
}

.masthead-inner-left-shape {
  left: 0;
  width: 308px;
  height: 200px;
  z-index: 999;
}

.masthead-inner-right-shape {
  right: 0;
  left: unset;
  width: 334px;
  height: 160px;
  z-index: 999;
}

.masthead-inner-overlay {
  width: 100%;
  position: relative;
  z-index: 9;
  background: linear-gradient(180deg, rgba(60, 60, 60, 0.6) 0%, rgba(60, 60, 60, 0.4) 100%);
  padding: 46px 0 40px;
}

.masthead-inner-overlay .container {
  position: relative;
  z-index: 9999;
}

.masthead-inner-content {
  text-align: center;
}

.masthead-inner-content h1:last-child {
  padding: 0;
}

.masthead-inner-content h1,
.masthead-inner-content p {
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: #fff;
}

.has-mtsnb .page-header {
  margin-top: 0 !important;
}

.masthead-background {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.masthead-background h1 {
  color: #fff;
  z-index: 999;
}

.masthead-overlay-color {
  background: rgb(60, 60, 60);
  background: linear-gradient(90deg, rgba(60, 60, 60, 0.6) 0%, rgba(60, 60, 60, 0.4) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

.masthead-overlay-left,
.masthead-overlay-right {
  position: absolute;
  overflow: hidden;
  z-index: 99;
  max-height: 100%;
  max-width: 48%;
  top: -30px;
}

.masthead-overlay-left {
  left: 0px;
}

.masthead-overlay-right {
  right: 0px;
}

.masthead-background-image {
  position: absolute;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  top: 0;
}

@media (min-width: 768px) {
  .masthead-inner-overlay {
    padding: 60px 0 50px;
  }

  .masthead-background {
    min-height: 225px;
  }

  .masthead-inner-banner:before {
    background: url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/inner-banner-left-shape.png) no-repeat left top;
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    max-width: 524px;
  }

  .masthead-inner-banner:after {
    background: url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/inner-banner-right-shape.png) no-repeat right top;
    content: " ";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 567px;
  }

  .masthead-inner-banner:before,
  .masthead-inner-banner:after {
    width: 100%;
    height: 100%;
    background-size: contain;
    z-index: 17;
  }
}

@media (min-width: 1025px) {
  .masthead-inner {
    margin-top: 160px;
  }

  .masthead-background {
    min-height: 275px;
  }

  .masthead-inner-banner img {
    -o-object-position: center;
    object-position: center;
  }

  .masthead-inner-left-shape img,
  .masthead-inner-right-shape img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    font-family: "object-fit: cover;";
  }

  .masthead-inner-left-shape img {
    -o-object-position: right;
    object-position: right;
  }

  .masthead-inner-right-shape img {
    -o-object-position: left;
    object-position: left;
  }

  .masthead-inner-left-shape {
    width: 27.6%;
    height: 340px;
    max-width: 524px;
  }

  .masthead-inner-right-shape {
    width: 29.8%;
    height: 271px;
    max-width: 567px;
  }

  .masthead-inner-overlay {
    padding: 133px 0 132px;
  }
}

@media (min-width: 1200px) {
  .masthead-background {
    min-height: 300px;
  }
}

@media (min-width: 1366px) {
  .masthead-background {
    min-height: 350px;
  }
}

.breadcrumbs {
  margin: 0;
  padding: 16px 0;
  background: #f5f5f5;
  text-align: center;
}

.breadcrumbs ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.breadcrumbs li {
  padding: 0 20px 0 0;
  margin: 0 10px 0 0;
  color: #141414;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.75em;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

.breadcrumbs li::after {
  display: none;
}

.breadcrumbs li::before {
  content: "\e916";
  font-family: "icomoon";
  color: #fc0;
  position: absolute;
  top: 1px;
  right: 0;
  background: transparent;
  width: auto;
  height: auto;
  left: unset;
  border-radius: unset;
  font-size: 10px;
}

.breadcrumbs li:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

.breadcrumbs li:last-child::before {
  display: none;
}

.breadcrumbs a {
  color: #141414;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}

.breadcrumbs a,
.breadcrumbs .breadcrumb_last {
  text-transform: uppercase;
  font-size: 16px;
}

.breadcrumbs a:hover {
  color: #fc0;
}

.breadcrumbs .separator {
  position: relative;
  margin: 0 5px;
}

.breadcrumbs .separator:before {
  content: " ";
  border: 5px solid transparent;
  border-left-color: #fc0;
  border-right: none;
  display: inline-block;
}

.wp-pagenavi {
  margin-top: 50px;
  padding-top: 30px;
  margin-bottom: 40px;
}

.wp-pagenavi span.pages {
  display: none;
}

.wp-pagenavi a,
.wp-pagenavi span {
  padding: 6px 12px;
  margin: 4px;
  box-sizing: border-box;
}

.wp-pagenavi span.current {
  background-color: #ffcd11;
  border-color: #ffcd11;
  color: #1e1e1e;
}

.wp-pagenavi a:hover {
  background-color: #1e1e1e;
  color: #fff;
}

.quick-search {
  padding: 25px 0 60px;
  background: #272727;
  margin-top: -1px;
}

.quick-search-wrapper {
  position: relative;
}

.quick-search-layout {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.quick-search-layout-header {
  text-align: center;
  padding: 15px 12px;
  background: #fc0;
  /* border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
}

.quick-search-layout-header h4 {
  padding: 0;
  color: #1c1c1c;
  font-size: 18px;
}

.quick-search-control {
  padding: 25px 30px 40px;
  border-radius: 4px;
}

.quick-search-field {
  margin-bottom: 20px;
}

.quick-search-field > label {
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  color: #1c1c1c;
}

.quick-search-field .selectric {
  height: 45px;
}

.quick-search-field .selectric .button {
  color: #ffcd11;
  border-left: none;
  height: 45px;
  width: 45px;
}

.quick-search-field .selectric .button:after {
  border-top-color: #ffcd11 !important;
  border: 5px solid transparent;
}

.quick-search-field .selectric .label {
  font-size: 14px;
  height: 45px;
  line-height: 45px;
}

.quick-search-field .selectric-items li {
  font-size: 14px;
}

.quick-search-field .selectric-items li:before {
  display: none;
}

.quick-search-field-button {
  padding-top: 5px;
}

.quick-search-field-button .btn {
  height: 100%;
}

.quick-search-options-box {
  width: 100%;
  padding: 7px;
  background-color: #eaeaea;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.quick-search-options-box [type="radio"] {
  visibility: hidden;
  position: absolute;
  width: 50%;
  z-index: 1;
}

.quick-search-options-box [type="radio"]#new {
  left: 0;
}

.quick-search-options-box [type="radio"]#used {
  right: 0;
}

.quick-search-options-box label {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #999;
  font-weight: 700;
  border-radius: 3px;
  width: 50%;
  float: left;
  text-align: center;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 0;
}

.quick-search-options-box [type="radio"]:checked + label {
  color: #fc0;
  background: #666;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

@media (min-width: 768px) {
  .quick-search {
    padding: 0 0 0;
    background: transparent;
    margin-top: 0px;
  }

  .quick-search-layout {
    position: relative;
    z-index: 9;
    top: 20px;
    left: 0;
  }

  .quick-search-layout-header {
    padding: 14px 16px;
  }

  .quick-search-layout-header h4 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .quick-search-control {
    padding: 30px 44px 25px;
  }

  .quick-search-field,
  .quick-search-field-button {
    padding: 0 15px;
    margin: 0 0 20px;
  }

  .quick-search-field {
    width: 36%;
  }

  .quick-search-options {
    width: 27%;
  }

  .quick-search-field-button {
    width: 28%;
  }

  .quick-search-control-flex-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -15px;
  }

  .quick-search-options-box label {
    padding: 9px 10px;
  }
}

@media (min-width: 1025px) {
  .quick-search {
    margin-top: 0px;
  }

  .quick-search-field .selectric {
    height: 50px;
  }

  .quick-search-field .selectric .button {
    height: 50px;
    width: 50px;
  }

  .quick-search-field .selectric .button:after {
    border: 6px solid transparent;
  }

  .quick-search-field .selectric .label {
    height: 50px;
    line-height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .quick-search .container {
    padding: 0 16px;
  }
}

@media (min-width: 1200px) {
  .quick-search-control {
    padding: 25px;
  }

  .quick-search-field,
  .quick-search-field-button {
    padding: 0 10px;
    margin: 0 0 0;
  }

  .quick-search-control-flex-box {
    margin: 0 -10px;
  }

  .quick-search-options-box {
    border-radius: 4px;
  }

  .quick-search-field {
    width: 24.5%;
  }

  .quick-search-options {
    width: 13.4%;
  }

  .quick-search-field-button {
    width: 12.6%;
  }
}

.page-body {
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-content {
  position: relative;
}

.loading-icon {
  background: #fff url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/ajax-loader.gif) no-repeat center center;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.type-position .page-content ul {
  list-style: none;
  margin-left: 0;
}

.type-position .page-content ul:last-child {
  padding-left: 20px;
}

.type-position .page-content {
  font-size: 18px;
  line-height: 1.55em;
  color: #666;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

.type-position .page-content strong,
.type-position .page-content b {
  color: #1c1c1c;
}

@media (max-width: 500px) {
  .page-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
    max-width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    height: auto;
  }
}

@media (min-width: 768px) {
  .page-body {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

@media (min-width: 1025px) {
  .page-body {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .page-body-shift {
    position: relative;
    padding-top: 0;
  }

  .page-content .loading-icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

.home-deals {
  position: relative;
  background: #f5f5f5;
}

.home-deals-graphics {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-deals-graphics img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.home-deals-content-wrapper {
  position: relative;
  padding: 60px 0 60px;
}

.home-deals-header {
  text-align: center;
}

.home-deals-box {
  margin-bottom: 30px;
  margin-top: 13px;
}

.home-deals-box .slick-track {
  display: flex;
}

.home-deals-box .slick-slide {
  display: block;
  height: inherit;
}

.home-deals-box .slick-prev {
  right: 90px;
  left: initial;
  top: calc(100% - 45px);
}

.home-deals-box .slick-prev:after {
  content: "";
  border: 12px solid transparent;
  border-right-color: #272727;
  border-left: none;
}

.home-deals-box .slick-prev:hover:after {
  border-right-color: #ffcd11;
}

.home-deals-box .slick-next {
  right: 90px;
  top: calc(100% - 45px);
}

.home-deals-box .slick-next:after {
  content: "";
  border: 12px solid transparent;
  border-left-color: #272727;
  border-right: none;
}

.home-deals-box .slick-next:hover:after {
  border-left-color: #ffcd11;
}

.home-deals-box .slick-slide {
  padding: 0px;
}

.detail-box .slick-slide {
  display: block;
  height: inherit;
}

.detail-box .slick-prev {
  left: 90px !important;
  left: initial;
  top: calc(100% - 45px);
}

.detail-box .slick-prev:after {
  content: "";
  border: 12px solid transparent;
  border-right-color: #272727;
  border-left: none;
}

.detail-box .slick-prev:hover:after {
  border-right-color: #ffcd11;
}

.detail-box .slick-next {
  right: 90px;
  top: calc(100% - 45px);
}

.detail-box .slick-next:after {
  content: "";
  border: 12px solid transparent;
  border-left-color: #272727;
  border-right: none;
}

.detail-box .slick-next:hover:after {
  border-left-color: #ffcd11;
}

.detail-box .slick-slide {
  padding: 0 10px;
}

.home-deals-box button {
  position: absolute;
  top: auto;
  bottom: 33%;
}

.home-deals-item-box {
  height: 100%;
  width: 100%;
  position: relative;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.home-deals-image {
  width: 100%;
  position: relative;
}

.home-deals-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
  position: relative;
  -o-object-position: left;
  object-position: left;
}

.home-deals-content {
  padding: 33px 20px 70px;
}

.home-deals-content .btn {
  margin-top: 8px;
}

.home-deals-content p {
  line-height: 1.3;
}

.home-deals-footer {
  text-align: center;
}

@media only screen and (min-width: 1025px) {
  .home-deals-item-box {
    align-items: center;
  }

  .js-home-deals-slider .slick-slide {
    height: 100%;
  }

  .home-deals-image {
    display: block;
  }

  .home-deals-image img {
    position: relative;
  }
}

@media (max-width: 767px) {
  .home-deals-header a {
    display: none;
  }
}

@media (min-width: 600px) {
  .home-deals-content {
    padding: 33px 30px 30px 30px;
  }

  .home-deals-content p {
    line-height: 1.625;
  }
}

@media (min-width: 768px) {
  .home-deals-header {
    text-align: left;
    padding: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .home-deals-header h2 {
    padding: 0;
    margin-bottom: 0;
  }

  .home-deals-box {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .home-deals-content {
    padding: 30px 40px 60px 40px;
  }

  .home-deals-content .btn {
    margin-top: 13px;
  }

  .home-deals-footer {
    display: none;
  }

  .home-deals-box .slick-next {
    margin-right: -62px;
    right: 82px;
  }

  .home-deals-box .slick-prev,
  .home-deals-box .slick-next {
    top: calc(100% - 46px);
  }
}

@media (min-width: 1025px) {
  .home-deals-header h2 {
    font-size: 42px;
  }

  .home-deals-image {
    width: 45%;
    height: 100%;
  }

  .home-deals-content {
    width: 43%;
    position: relative;
    padding: 70px 60px 80px 60px;
    top: 0;
  }

  .home-deals-content .btn {
    margin-top: 15px;
  }

  .home-deals-box {
    margin-bottom: 80px;
  }

  .home-deals-box .slick-slide {
    display: flex;
  }

  .home-deals-header {
    padding: 0 0 20px;
    position: relative;
  }

  .home-deals-header__link {
    font-size: 15px;
    position: absolute;
    right: 30px;
    top: 115px;
    z-index: 1;
  }

  .home-deals-box .slick-prev,
  .home-deals-box .slick-next {
    top: calc(100% - 50px);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home-deals-content h3 {
    font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .home-deals-box {
    max-height: 656px;
    overflow: hidden;
  }
}

.image-button {
  position: relative;
  padding: 50px 0 60px;
}

.image-button .container {
  position: relative;
  z-index: 9;
}

.image-button-left-panel {
  padding-bottom: 40px;
}

.image-button-left-panel h2 span {
  font-weight: 400;
}

.image-button-left-panel .btn {
  margin-top: 13px;
}

.image-button-right-panel {
  margin: 0;
}

.js-image-button.slick-slider {
  margin: 0;
}

.js-image-button.slick-slider [class*="col-"] {
  padding: 0;
}

.js-image-button.slick-slider .slick-list {
  margin: 0 -22px;
  padding-bottom: 36px !important;
}

.js-image-button.slick-slider .slick-slide {
  padding: 0 12px;
}

.image-button-item {
  position: relative;
  width: 100%;
  height: 200px;
}

.image-button-item-ma {
  position: relative;
  width: 100%;
  height: 300px;
}

.image-button-item a {
  display: block;
  height: 100%;
  width: 100%;
  font-weight: 400;
  border: none;
  position: relative;
}

.image-button-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
  transition: all 0.4s ease-in-out;
}

.image-button-item a:hover img {
  opacity: 0.5;
}

.image-button-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.image-button-overlay::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 142px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.102) 28.6%, #000000 100%);
}

.image-button-overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
}

.image-button-title {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 22px;
  line-height: 1em;
  padding: 12px 70px 12px 20px;
  transition: all 0.4s ease-in-out;
  font-family: "Source Sans Pro", sans-serif;
}

.image-button-title-wrap {
  position: relative;
  z-index: 9;
}

.image-button-title .arrow-icon {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 0;
  height: 100%;
  width: 55px;
}

.image-button-title .arrow-icon span {
  position: relative;
  width: 60px;
  height: 100%;
  display: block;
}

.image-button-title .arrow-icon span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 8px solid transparent;
  border-left-color: #272727;
  border-right: none;
}

.image-button-item a:hover .image-button-title {
  color: #1c1c1c;
}

.image-button-title-clippy {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 100%;
  text-align: right;
  display: flex;
  font-size: 16px;
  color: #272727;
  flex-direction: column;
  justify-content: center;
  -webkit-filter: drop-shadow(0 0 15px rgba(88, 71, 0, 0.1));
  filter: drop-shadow(0 0 15px rgba(88, 71, 0, 0.1));
  transition: width 0.4s ease-in-out;
}

.image-button-title-clippy::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fc0;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 15px 0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 15px 0);
  transition: all 0.4s ease-in-out;
}

.image-button-item a:hover .image-button-title-clippy {
  width: 100%;
}

.image-button-item a:hover .image-button-title-clippy::after {
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
}

.image-button-graphics-right,
.image-button-graphics-left {
  position: absolute;
}

.image-button-graphics-right {
  top: 0;
  right: 0;
  width: 454px;
  height: 220px;
}

.image-button-graphics-right img,
.image-button-graphics-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.image-button-graphics-right img {
  -o-object-position: left;
  object-position: left;
}

.image-button-graphics-left img {
  -o-object-position: right;
  object-position: right;
}

@media (min-width: 768px) {
  .image-button {
    padding: 60px 0 42px;
  }

  .image-button-item {
    margin-bottom: 30px;
  }

  .image-button-item {
    height: 240px;
  }

  .image-button-overlay::after {
    height: 120px;
  }
}

@media (min-width: 1025px) {
  .image-button {
    padding: 100px 0 80px;
  }

  .image-button .col-md-5 {
    width: 35.5%;
  }

  .image-button-left-panel h2 {
    font-size: 42px;
  }

  .image-button .col-md-7 {
    width: 64.5%;
  }

  .image-button-item {
    margin-bottom: 32px;
  }

  .image-button-item {
    height: 242px;
  }

  .image-button-overlay::after {
    height: 122px;
  }

  .image-button-right-panel {
    padding-left: 58px;
  }

  .image-button-title {
    font-size: 20px;
    padding: 13px 70px 13px 15px;
  }

  .image-button-left-panel .btn {
    margin-top: 15px;
  }

  .image-button-left-panel {
    padding-bottom: 0;
  }

  .image-button-graphics-right {
    top: unset;
    bottom: 0;
    width: 37.4%;
    height: 337px;
  }

  .image-button-graphics-left {
    top: 0;
    left: 0;
    width: 30%;
    height: 283px;
  }
}

.home-about {
  position: relative;
  background: #272727;
  padding: 60px 0;
}

.home-about .container {
  position: relative;
  z-index: 99;
}

.home-about-graphics-right,
.home-about-graphics-left {
  position: absolute;
  bottom: 0;
}

.home-about-graphics-right {
  width: 370px;
  height: 227px;
  right: 0;
}

.home-about-graphics-right img,
.home-about-graphics-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.home-about-graphics-right img {
  -o-object-position: left;
  object-position: left;
}

.home-about-graphics-left img {
  -o-object-position: right;
  object-position: right;
}

.home-about-left-panel {
  background: #666;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.home-about-image {
  width: 100%;
  height: 180px;
  position: relative;
}

.home-about-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.home-about-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-about-image-overlay .video-button-link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.video-button-outer {
  width: 72px;
  height: 72px;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #1c1c1c;
  font-size: 26px;
  transition: all 0.4s ease-in-out;
  -webkit-animation: VideoPulse 2s infinite;
  animation: VideoPulse 2s infinite;
}

@-webkit-keyframes VideoPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes VideoPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  }

  70% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes VideoPulseHover {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255, 204, 0, 0.3);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgb(255, 204, 0, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes VideoPulseHover {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 204, 0, 0.3);
  }

  70% {
    box-shadow: 0 0 0 30px rgb(255, 204, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(255, 204, 0, 0);
  }
}

.video-button-inner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fc0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: background 0.4s ease-in-out;
}

.video-button-inner span {
  margin-left: 6px;
}

.video-button-inner .play-btn:before {
  content: "";
  border: 12px solid transparent;
  border-left-color: #272727;
  border-right: none;
  display: block;
}

.home-about-image:hover .video-button-outer {
  -webkit-animation: VideoPulseHover 2s infinite;
  animation: VideoPulseHover 2s infinite;
  background: rgb(255, 204, 0, 0.3);
}

.home-about-short-content {
  padding: 22px 30px 30px;
}

.home-about-short-content-flex-box {
  border-bottom: 1px solid #7c7c7c;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.home-about-short-content-flex-box:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.home-about-short-content-heading h5 {
  color: #fc0;
  padding: 0 0 3px;
  margin: 0 0 0;
}

.home-about-short-content-info p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0;
}

.home-about-right-panel {
  padding-top: 30px;
}

.home-about-right-panel h2 {
  color: #fc0;
}

.home-about-right-panel h2 span {
  font-weight: 400;
}

.home-about-right-panel p {
  color: #fff;
}

.home-about-right-panel .btn {
  margin-top: 13px;
}

@media (min-width: 768px) {
  .home-about {
    padding: 60px 0 72px;
  }

  .home-about-image {
    height: 364px;
  }

  .video-button-outer {
    width: 92px;
    height: 92px;
    font-size: 35px;
  }

  .video-button-inner {
    width: 76px;
    height: 76px;
  }

  .video-button-inner .play-btn {
    margin-left: 29px;
  }

  .video-button-inner .play-btn:before {
    border: 22px solid transparent;
    border-left-color: #272727;
  }

  .home-about-short-content {
    padding: 25px 40px 35px;
  }

  .home-about-short-content-flex-box {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .home-about-short-content-heading {
    width: 100px;
    padding-right: 10px;
  }

  .home-about-short-content-heading h5 {
    padding: 0;
  }

  .home-about-short-content-info {
    width: calc(100% - 100px);
  }

  .home-about-short-content-info p {
    font-size: 16px;
  }

  .home-about-right-panel {
    padding-top: 40px;
  }
}

@media (min-width: 1025px) {
  .home-about {
    padding: 96px 0 114px;
  }

  .home-about-graphics-right {
    top: 0;
    height: 331px;
    width: 37.4%;
  }

  .home-about-graphics-left {
    width: 34%;
    height: 381px;
  }

  .home-about-left-panel {
    max-width: 662px;
  }

  .home-about-right-panel h2 {
    font-size: 42px;
  }

  .video-button-outer {
    width: 104px;
    height: 104px;
    font-size: 40px;
  }

  .video-button-inner {
    width: 86px;
    height: 86px;
  }

  .home-about-short-content-info p {
    font-size: 17px;
  }

  .home-about-right-panel {
    padding-top: 0;
    max-width: 565px;
    margin: 0 0 0 auto;
  }

  .home-about-right-panel h2 span {
    display: block;
  }

  .home-about-right-panel .btn {
    margin-top: 15px;
  }
}

.home-testimonials {
  position: relative;
  padding: 50px 0 60px;
}

.flexible-testimonials {
  background: #fafafa;
}

.flexible-testimonials .container {
  padding: 0 22px;
}

.home-testimonials-wrapper {
  position: relative;
  padding: 0 22px;
}

.home-testimonials-header {
  padding-bottom: 5px;
}

.home-testimonials-header h2 {
  padding: 0;
}

.home-testimonials-slider {
  margin-bottom: 30px;
}

.home-testimonials-slider .slick-list {
  margin: 0 -20px;
  padding: 20px 0 62px;
}

.home-testimonials-slider .slick-arrow {
  top: auto;
}

.home-testimonials-slider .slick-track {
  display: flex;
}

.home-testimonials-slider .slick-slide {
  padding: 0 20px;
  height: auto;
}

.home-testimonials-slider .slick-slide > div {
  height: 100%;
}

.home-testimonials-slider-item {
  height: 100%;
}

.home-testimonials-slider-item-column {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 20px rgb(0, 0, 0, 0.1);
  padding: 28px 30px 80px;
  position: relative;
  overflow: hidden;
  text-align: left;
}

.home-testimonials-slider-item-column::after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffc10e;
}

.home-testimonials-slider-item-column h4 {
  font-size: 17px;
  color: #1c1c1c;
  font-weight: 400;
  line-height: 1.58em;
  margin: 0 0 20px;
  text-transform: unset;
  padding: 0;
  display: block;
}

.home-testimonials-slider-item-column h5 {
  color: #666;
  font-size: 18px;
  line-height: 1.44em;
  margin: 0;
  padding: 0;
}

.home-testimonials-slider-item-column span {
  color: #666;
  font-family: "Source Sans Pro", sans-serif;
}

.home-testimonials-slider-item-column h5 span {
  font-weight: 700;
}

.home-testimonials-slider-item-column .title-wrap {
  position: relative;
  padding-left: 25px;
  display: block;
}

.home-testimonials-slider-item-column .title-wrap span {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .home-testimonials-header .btn.hidden-xs-down {
    display: none;
  }
}

@media (min-width: 768px) {
  .home-testimonials {
    padding: 60px 0 70px;
    text-align: left;
  }

  .home-testimonials-wrapper {
    padding: 0 54px;
  }

  .home-testimonials-slider-wrapper > .btn {
    margin-top: -137px;
  }

  .home-testimonials-slider-wrapper .btn.hidden-sm-up {
    display: none;
  }

  .home-testimonials-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 15px;
  }

  .home-testimonials-header h2 {
    width: 50%;
    text-align: left;
  }

  .home-testimonials-header h2 span {
    display: block;
    font-weight: 400;
  }

  .home-testimonials-slider .slick-list {
    margin: 0 -16px;
    padding: 20px 0 74px;
  }

  .home-testimonials-slider .slick-slide {
    padding: 0 16px;
  }

  .home-testimonials-slider-item-column {
    padding: 30px 32px 84px;
  }

  .home-testimonials-slider-item-column h4 {
    font-size: 18px;
  }

  .home-testimonials-slider-item-column h5 {
    bottom: 40px;
  }

  .home-testimonials-slider {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .home-testimonials-banner {
    display: none;
  }
}

@media (min-width: 1025px) {
  .home-testimonials-header {
    padding-top: 30px;
  }

  .home-testimonials-header h2 {
    font-size: 42px;
  }
}

@media (min-width: 1200px) {
  .home-testimonials {
    padding: 80px 0;
  }

  .home-testimonials.flexible-testimonials {
    padding: 106px 0 100px;
  }

  .home-testimonials-wrapper {
    padding: 0 0;
  }

  .home-testimonials-header {
    padding-top: 0;
  }

  .home-testimonials-slider-wrapper > .btn {
    margin-left: 80px;
  }

  .home-testimonials-slider .slick-list {
    margin: 0 0;
    padding: 20px 0 80px !important;
    /* margin-left: -96px; */
  }

  /* .home-testimonials-slider .polite {
    margin: 0 0;
    padding: 20px 0 80px !important;
    margin-left: -96px;
  } */

  .home-testimonials-slider .slick-slide {
    opacity: 0.5;
    transition: all 0.4s ease-in-out;
  }

  .home-testimonials-slider .slick-slide.slick-active {
    opacity: 1;
  }

  .home-testimonials-banner {
    width: 43.7%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .home-testimonials-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    font-family: "object-fit: cover;";
  }

  .home-testimonials-right-panel {
    float: right;
    width: 56.3%;
    position: relative;
    z-index: 9;
    padding: 34px 0;
  }

  .home-testimonials-slider-item-column {
    padding: 38px 50px 0px;
  }

  .home-testimonials-slider-item-column h4 {
    font-size: 15px;
    line-height: 1.5em;
  }

  .home-testimonials-slider-item-column h5 {
    font-size: 17px;
  }

  .home-testimonials-header {
    width: 100%;
    max-width: 808px;
    padding-left: 60px;
    padding-bottom: 20px;
    padding-right: 16px;
  }

  .flexible-testimonials .home-testimonials-header {
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
  }

  .flexible-testimonials .home-testimonials-slider .slick-list {
    margin-left: 0;
  }

  .flexible-testimonials .home-testimonials-right-panel {
    width: 100%;
    padding: 0;
  }

  .home-testimonials-slider .slick-prev {
    right: 88px;
  }

  .home-testimonials-slider .slick-next {
    right: 16px;
  }
}

@media (min-width: 1440px) {
  .flexible-testimonials .home-testimonials-right-panel {
    width: 97%;
  }
}

@media (min-width: 1800px) {
  .flexible-testimonials .home-testimonials-right-panel {
    width: 86%;
  }

  .home-testimonials-slider-item-column {
    width: 420px;
  }

  .home-testimonials-slider .slick-next {
    right: 280px;
  }

  .home-testimonials-slider .slick-prev {
    right: 352px;
  }
}

@media (min-width: 2000px) {
  .flexible-testimonials .home-testimonials-right-panel {
    width: 84%;
  }

  .home-testimonials-slider .slick-next {
    right: 335px;
  }

  .home-testimonials-slider .slick-prev {
    right: 406px;
  }
}

@media (min-width: 2400px) {
  .flexible-testimonials .home-testimonials-right-panel {
    width: 78.4%;
  }

  .home-testimonials-slider .slick-next {
    right: 672px;
  }

  .home-testimonials-slider .slick-prev {
    right: 744px;
  }
}

.cta {
  position: relative;
}

.cta-banner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cta-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.cta-overlay {
  position: relative;
  z-index: 9;
  background: rgba(39, 39, 39, 0.7);
  text-align: center;
  padding: 50px 0 60px;
}

.cta h1,
.cta h2,
.cta h3,
.cta h4 {
  color: #fc0;
  margin-bottom: 0;
}

.cta h2 {
  padding: 0 0 15px;
}

.cta h2 span {
  font-weight: 400;
}

.cta p {
  color: rgba(255, 255, 255, 0.82);
  padding-bottom: 25px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .cta-overlay {
    padding: 60px 0 70px;
  }

  .cta-content {
    width: 100%;
    max-width: 1098px;
    margin: 0 auto;
  }
}

@media (min-width: 1025px) {
  .cta h2 {
    font-size: 42px;
  }

  .cta-overlay {
    padding: 70px 0 86px;
  }

  .cta-content .btn {
    margin-top: 5px;
  }
}

.page-sidebar .widget {
  padding-bottom: 2.25em;
}

.page-sidebar .heading-title {
  padding: 0.889em;
  font-weight: 900;
}

.page-sidebar .heading-title b {
  font-size: 18px;
}

.page-sidebar .heading-title span {
  display: block;
  font-family: Calibri, Candara, Segoe, "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.widget-sublist-pages,
.sublist-pages {
  font-size: 16px;
  font-weight: 600;
}

.widget-sublist-pages h5,
.page-sidebar h5 {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 0.136em;
  margin-bottom: 14px;
}

.widget-sublist-pages a,
.sublist-pages a {
  color: #272727;
  text-transform: none;
}

.widget-sublist-pages a:hover:after,
.widget-sublist-pages li.current-menu-item a:after,
.widget-sublist-pages li.current-page-parent > a,
.widget-sublist-pages li.current-page-ancestor > a,
.sublist-pages li.current-menu-item a:after {
  content: "\bb";
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 0.25em;
}

.page-sidebar .textwidget input,
.page-sidebar .textwidget textarea {
  background: #f2f2f2;
}

.sublist-pages ul li {
  padding-left: 0;
  margin-bottom: 0;
}

.sublist-pages ul li:before {
  display: none;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

.featured-used {
  margin-bottom: 3.125em;
}

.featured-used .heading-title {
  margin-bottom: 2.083em;
}

.featured-used--name {
  background: #1e1e1e;
  color: #fff;
  display: block;
  font: 600 14.03px "Inter", sans-serif;
  padding: 0.713em 1.14em;
  text-transform: uppercase;
}

.featured-used a:hover .featured-used--name {
  background: #474d59;
  color: #fff;
}

.featured-used__content {
  background: #f5f5f5;
  padding: 1em 1.125em;
}

.featured-used a:hover .featured-used__content {
  background: #eaeaea;
}

.featured-used__content--img {
  float: left;
  width: 60%;
  height: 165px;
  overflow: hidden;
}

.featured-used__content-info {
  float: right;
  width: 38%;
}

.featured-used__content-info--text {
  color: #575757;
  padding: 0.75em 0;
  text-transform: uppercase;
}

.featured-used__content-info--text:first-child {
  border-bottom: 1px solid #e1e1e1;
}

.featured-used__content-info--text b,
.featured-used__content-info--text strong {
  font-weight: 900;
}

@media (min-width: 1025px) {
  .featured-used .heading-title {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.page-preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/ajax-loader.gif) no-repeat center center;
}

.page-preloader__logo {
  position: fixed;
  top: 30%;
  left: 50%;
  margin-left: -105px;
}

@media (min-width: 600px) {
  .page-preloader__logo {
    top: 35%;
  }
}

@media (min-width: 768px) {
  .page-preloader__logo {
    top: 40%;
  }
}

.button-image--container {
  list-style: none !important;
  padding-left: 0 !important;
}

@media (min-width: 768px) {
  .button-image--container > [class*="col-"]:nth-child(3n + 1) {
    clear: left;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .button-image--container > [class*="col-"]:nth-child(odd) {
    clear: left;
  }
}

.button-image--container li:before {
  display: none;
}

.button-image {
  border: 1px solid #ebebeb;
  margin-bottom: 2em;
}

.button-image--name {
  background: #505763;
  color: #fff;
  display: table;
  font-size: 17px;
  height: 65px;
  line-height: 1.106em;
  margin-bottom: 0;
  padding: 0 1em;
  text-align: center;
  width: 100%;
}

.button-image--container [class^="col-"][data-sr] {
  visibility: hidden;
}

.button-image--container [class^="col-"]:nth-child(3n + 2) .button-image--name {
  background: #353a45;
}

.button-image--name span {
  display: table-cell;
  vertical-align: middle;
}

.button-image img {
  width: 100%;
}

.button-image:hover .button-image--name {
  background: #1e1e1e !important;
}

.card {
  display: block;
  margin-top: 10px;
}

.card:first-child {
  margin-top: 0;
}

.card__primary-info {
  background: #f2f2f2;
  border-top: 10px solid #343b45;
  margin: 0;
  padding: 30px 20px 20px 20px;
  position: relative;
}

.card__primary-info:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 31px 0 31px;
  border-color: #343b45 transparent transparent transparent;
  position: absolute;
  top: -6px;
  transition: all 0.2s ease;
  margin-left: -31px;
  left: 50%;
}

.card:hover .card__primary-info:after {
  top: 0;
}

.card__primary-info address {
  font-style: normal;
}

.card__secondary-info {
  background: #e6e6e6;
  color: #1c1c1c;
  padding: 30px;
  position: relative;
}

.card__secondary-info .used-cat-cert {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 100px;
}

.card__secondary-info .used-cat-cert img {
  display: block;
  height: auto;
  width: 100%;
}

.card__title {
  text-transform: none;
}

.card--product:hover .button {
  background: #fcdb5f;
}

.card--product:hover .button:after {
  border-color: transparent transparent transparent #fcdb5f;
}

@media (min-width: 600px) {
  .card {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .card__secondary-info .used-cat-cert {
    max-width: 130px;
  }

  .card__primary-info,
  .card__secondary-info {
    display: table-cell;
  }

  .card--product .card__primary-info,
  .card--product .card__secondary-info {
    width: 50%;
    vertical-align: top;
  }

  .card--location .card__primary-info {
    width: 40%;
    vertical-align: middle;
  }

  .card--location .card__secondary-info {
    width: 60%;
    vertical-align: middle;
  }

  .card__primary-info {
    border-top: none;
    border-left: 10px solid #343b45;
  }

  .card--product .card__primary-info {
    padding: 20px 20px 20px 30px;
  }

  .card--location .card__secondary-info,
  .card--location .card__primary-info {
    padding: 30px;
  }

  .card__primary-info:after {
    border-style: solid;
    border-width: 31px 0 31px 16px;
    border-color: transparent transparent transparent #343b45;
    top: 50%;
    margin-top: -31px;
    margin-left: 0;
    left: -6px;
  }

  .card:hover .card__primary-info:after {
    top: 50%;
    left: 0;
  }

  .card--product .card__secondary-info {
    padding: 20px 30px;
  }
}

@media (min-width: 768px) {
  .card .product-stats-summary__row {
    float: left;
    width: 50%;
  }

  .card--product .card__primary-info {
    width: 40%;
  }

  .card--product .card__secondary-info {
    width: 60%;
  }
}

@media (min-width: 1025px) {
  .card--product .card__primary-info {
    width: 30%;
  }

  .card--product .card__secondary-info {
    width: 70%;
  }

  .card--location .card__primary-info {
    width: 30%;
  }

  .card--location {
    margin-top: 5px;
  }
}

.left-image-right-content {
  position: relative;
  padding: 54px 0 60px;
}

.right-content {
  padding-bottom: 40px;
}

.right-content .btn {
  margin-top: 13px;
}

.left-image img {
  width: 100%;
}

@media (min-width: 768px) {
  .left-image-right-content {
    padding: 60px 0 72px;
  }

  .right-content {
    padding-bottom: 0;
  }
}

@media (min-width: 1025px) {
  .left-image-right-content {
    padding: 100px 0;
  }

  .right-content {
    padding-left: 48px;
  }

  .left-image-right-content .left-image-custom-grid {
    width: 43.8%;
    right: 56.2%;
  }

  .left-image-right-content .right-content-custom-grid {
    width: 56.2%;
    left: 43.8%;
  }

  .right-content .btn {
    margin-top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .left-image-right-content .container {
    padding: 0 22px;
  }
}

.flexible-image-cards {
  position: relative;
  background: #f2f2f2;
  padding: 0;
  margin-bottom: 2rem;
}

.flexible-image-cards-listing [class*="col-"] {
  margin-bottom: 30px;
}

.flexible-image-cards-header {
  text-align: center;
  padding: 0 0 40px;
}

.image-cards-box {
  height: 100%;
  width: 100%;
}

.image-cards-box a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease-in-out;
}

.card-image {
  max-height: 300px;
  overflow: hidden;
  position: relative;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.image-card-content {
  position: relative;
  padding: 35px 30px;
}

.image-card-btn {
  position: absolute;
  top: -20px;
  right: -1px;
  width: 52px;
  height: 40px;
  top: auto;
  bottom: 0;
}

.image-card-btn span {
  z-index: 99;
  position: relative;
}

.image-card-btn-text {
  display: none;
  font-weight: 700;
  font-size: 14px;
}

.image-card-btn-clippy {
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  height: 100%;
  text-align: right;
  display: flex;
  font-size: 14px;
  color: #272727;
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;
  -webkit-filter: drop-shadow(0 0 15px rgba(88, 71, 0, 0.1));
  filter: drop-shadow(0 0 15px rgba(88, 71, 0, 0.1));
  transition: width 0.4s ease-in-out;
}

.image-card-btn-clippy::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fc0;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 13px 0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 13px 0);
  transition: all 0.4s ease-in-out;
}

.image-card-btn-clippy .icon-right {
  position: relative;
}

.image-card-btn-clippy .icon-right:before {
  content: " ";
  border: 8px solid transparent;
  border-left-color: #272727;
  border-right: none;
  display: inline-block;
}

.flexible-image-cards-listing .row {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .flexible-image-cards {
    padding: 0;
  }

  .flexible-image-cards-listing .row {
    display: flex;
    flex-wrap: wrap;
  }

  .image-card-btn {
    width: 60px;
    height: 46px;
    top: -23px;
    top: auto;
    bottom: 0;
  }

  .image-card-btn-clippy {
    width: 60px;
    font-size: 16px;
    padding-right: 18px;
  }

  .flexible-image-cards-header {
    width: 100%;
    max-width: 662px;
    margin: 0 auto;
  }

  .image-card-content {
    padding: 20px 20px 40px;
  }

  .image-card-content h3 {
    font-size: 20px;
  }
}

@media (min-width: 1025px) {
  .flexible-image-cards {
    padding: 0;
  }

  .flexible-image-cards-listing [class*="col-"] {
    margin-bottom: 32px;
  }

  .flexible-image-cards-header {
    max-width: 1098px;
    padding: 0 0 50px;
  }

  .image-cards-box a:hover .image-card-btn-clippy {
    width: 100%;
  }

  .image-cards-box a:hover .image-card-btn-clippy::after {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }

  .image-card-btn {
    min-width: 168px;
    width: auto;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 42px 0 22px;
    color: #1c1c1c;
    top: auto;
    bottom: 0;
  }

  .image-card-btn-text {
    display: inline-block;
    z-index: 999;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  }

  .image-cards-box a:hover .image-card-btn-text {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .image-card-content h3,
  .image-card-content p {
    transition: all 0.4s ease-in-out;
  }

  .image-cards-box a:hover {
    background: #666;
  }

  .image-cards-box a:hover .image-card-content h3 {
    color: #fc0;
  }

  .image-cards-box a:hover .image-card-content p {
    color: #fff;
  }

  .image-card-content h3 {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .flexible-image-cards .container {
    padding: 0 22px;
  }
}

.wysiwyg {
  position: relative;
}

.wysiwyg-texture {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wysiwyg-texture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.wysiwyg-wrapper {
  position: relative;
  z-index: 99;
  padding: 50px 0 60px;
}

.wysiwyg-content .btn {
  margin-top: 13px;
}

.wysiwyg-content .btn + .btn {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .wysiwyg .container {
    padding: 0 85px;
  }

  .wysiwyg-wrapper {
    padding: 60px 0 70px;
  }

  .wysiwyg-content .btn + .btn {
    margin-top: 13px;
  }
}

@media (min-width: 1025px) {
  .wysiwyg .container {
    padding: 0 16px;
  }

  .wysiwyg-content {
    width: 100%;
    max-width: 1098px;
    margin: 0 auto;
  }

  .wysiwyg-wrapper {
    padding: 100px 0;
  }

  .wysiwyg-content .btn,
  .wysiwyg-content .btn + .btn {
    margin-top: 15px;
  }
}

.full-width-image-background {
  position: relative;
}

.full-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.full-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

.full-width-image-overlay {
  position: relative;
  z-index: 9;
}

.full-width-image-overlay .container {
  padding: 0 0;
}

.full-width-image-content {
  padding: 50px 22px 60px;
  background: #666;
}

.full-width-image-content h2 {
  color: #fc0;
}

.full-width-image-content p {
  color: #fff;
}

.full-image-graphics {
  width: 26.3%;
  max-width: 499px;
  height: 296px;
  position: absolute;
  top: 0;
  left: 0;
}

.full-image-graphics img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
}

@media (min-width: 768px) {
  .full-image {
    display: block;
  }

  .full-image:before {
    content: " ";
    display: block;
    width: 100%;
    max-width: 499px;
    max-height: 296px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/full-image-graphics.png) no-repeat left top;
    background-size: contain;
  }

  .full-width-image-overlay .container {
    padding: 0 54px;
  }

  .full-width-image-overlay {
    padding: 70px 0;
  }

  .full-width-image-content {
    padding: 40px 50px 50px;
    background: rgba(39, 39, 39, 0.85);
  }
}

@media (min-width: 1025px) {
  .full-width-image-overlay .container {
    padding: 0 16px;
  }

  .full-width-image-overlay {
    padding: 100px 0;
  }

  .full-width-image-content {
    width: 100%;
    max-width: 790px;
    padding: 60px 80px 70px;
  }
}

.card-cta {
  padding: 60px 0 40px;
}

.card-cta [class*="col-"] {
  margin-bottom: 20px;
}

.card-cta-box {
  position: relative;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.card-cta-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-cta-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit: cover;";
  transition: all 0.4s ease-in-out;
}

.card-cta-box:hover .card-cta-image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.card-cta-overlay-box {
  width: 100%;
  height: 100%;
  padding: 0 0 0;
  position: relative;
  z-index: 9;
  background: linear-gradient(90deg, rgba(39, 39, 39, 0.902) 0%, rgba(39, 39, 39, 0.6) 100%);
  padding: 30px;
}

.card-cta-content h3,
.card-cta-content p {
  color: #fff;
}

.card-cta-content .btn {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .card-cta {
    padding: 70px 0 50px;
  }

  .card-cta-overlay-box {
    padding: 60px 150px 50px 50px;
  }

  .card-cta-content .btn {
    margin-top: 13px;
  }

  .card-cta-content {
    width: 100%;
    max-width: 518px;
  }
}

@media (min-width: 1025px) {
  .card-cta {
    padding: 100px 0 70px;
  }

  .card-cta .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-cta [class*="col-"] {
    margin-bottom: 30px;
  }

  .card-cta-content h3 {
    padding: 0 0 13px;
  }

  .card-cta-content p {
    font-size: 17px;
  }

  .card-cta-content {
    max-width: 370px;
  }

  .card-cta-overlay-box {
    padding: 54px 135px 54px 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-cta .container {
    padding: 0 22px;
  }

  .card-cta-content h3 {
    font-size: 28px;
  }
}

.tabs {
  margin-top: 24px;
  margin-bottom: 24px;
}

.tabs__nav {
  background: #f2f2f2;
  font-size: 15px;
  font-weight: 700;
  list-style: none !important;
  padding-left: 0 !important;
  text-transform: uppercase;
}

.tab-link {
  display: block;
  position: relative;
  z-index: 1;
}

.tab-link span {
  color: #787878;
  display: block;
  padding: 10px 20px;
}

.tab-link::before,
.media-tabs .media-tabs-thumbnails li::before,
.product__actions li::before {
  display: none !important;
}

.tabs--small .tab-link span {
  padding: 5px 20px;
}

.tab-link.active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 20px 0 20px;
  border-color: #ddd transparent transparent transparent;
  position: absolute;
  bottom: -9px;
  margin-left: -20px;
  left: 50%;
  transition: all 0.3s ease;
}

.tab-link.active:after {
  border-color: #ddd transparent transparent transparent;
}

.tab-link.active span {
  background: #ddd;
  color: #272727;
}

.tabs__nav .tab-link {
  padding: 0;
}

.tabs__content {
  margin-top: 24px;
}

.tabs__tab {
  display: none;
}

.tabs__tab.active {
  display: block;
}

.tabs__content .tablepress {
  table-layout: fixed;
}

.tabs__content .tablepress img {
  max-width: 100%;
}

@media (min-width: 600px) {
  .tab-link {
    display: inline-block;
  }

  .tab-link span {
    padding: 10px 5px;
  }

  .tab-link:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 20px 0 20px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    margin-left: -20px;
    left: 50%;
    transition: all 0.3s ease;
  }
}

@media (min-width: 768px) {
  .tab-link span {
    padding: 10px;
  }
}

@media (min-width: 1024px) {
  .tab-link span {
    padding: 10px 20px;
  }
}

.social-links {
  text-align: center;
}

.social-links li {
  display: inline-block;
  padding-left: 0.95em;
}

.social-links li:first-child {
  padding-left: 0;
}

.social-links li a {
  background: #505763;
  color: #fff;
  font-size: 18px;
  padding: 0.111em 0.333em;
}

.page-footer__social {
  padding-left: 0;
  padding-bottom: 0;
}

.page-footer__social a:hover {
  background: #1e1e1e;
  color: #fff;
}

.social-links .icon-instagram:hover {
  background: #f58529;
}

.social-links .icon-facebook:hover {
  background: #415e9b;
}

.social-links .icon-twitter:hover {
  background: #000;
}

.social-links .icon-linkedin2:hover {
  background: #0177b5;
}

.social-links .icon-google-plus:hover {
  background: #dd4b39;
}

.social-links .icon-youtube3:hover {
  background: #cc181e;
}

@media (min-width: 768px) {
  .page-footer__social {
    text-align: right;
  }

  .page-footer__social li {
    padding-left: 10px;
  }
}

.page-content .post {
  border-bottom: 5px solid #bfbfbf;
  padding: 5em 0;
}

.page-content .post:first-of-type {
  padding-top: 15px;
}

.page-content .post:last-of-type {
  border-bottom: none;
}

.featured--post .post {
  border-bottom: 5px solid #fdcc02 !important;
}

.post__date-author {
  margin-bottom: 1.429em;
}

.post__date-author--author,
.post__date-author--date {
  font-weight: 700;
}

.post__comments-area {
  border-top: 1px solid #bfbfbf;
  margin-top: 2em;
  padding-top: 2em;
}

.post__comments-area p {
  margin-bottom: 1em;
}

#respond {
  margin-top: 1.5em;
}

.blog-share-icons {
  margin-bottom: 50px;
}

.blog-share-icons ul {
  list-style: none;
}

.blog-share-icons ul li:before {
  display: none;
}

.blog-share-icons li:first-child {
  padding-left: 0;
}

.blog-share-icons li {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.blog-share-icons li {
  padding-left: 10px;
}

.blog-share-icons li a {
  color: #fff;
  background: #353a45;
  padding: 0.111em 0.333em;
  font-size: 18px;
}

.blog-share-icons .icon-facebook:hover {
  background: #415e9b;
}

.blog-share-icons .icon-twitter:hover {
  background: #000;
}

.blog-share-icons .icon-linkedin2:hover {
  background: #0177b5;
}

.blog-share-icons .icon-google-plus:hover {
  background: #dd4b39;
}

.blog-share-icons .icon-mail:hover {
  background: #fc0;
}

.post__date-categories {
  background-color: #e9e9e9;
  padding: 4px;
  padding-left: 10px;
  text-transform: uppercase;
  color: #353a45;
  margin-bottom: 20px;
}

span.categories a {
  color: #353a45;
}

.post__entry .button {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .specials-deals .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.dropbox-item {
  display: none;
}

.deal {
  display: block;
  padding: 30px;
  border: 1px solid #ccc;
  transition: all 300ms ease;
}

.deal img {
  display: block;
  margin-bottom: 10px;
}

.deal:hover {
  padding: 26px;
  border: 4px solid #1e1e1e;
}

.tablepress img {
  max-width: 100%;
}

.page-footer {
  background-color: #474d59;
  padding-bottom: 60px;
}

.page-footer__main {
  padding: 50px 0;
}

.nav--footer {
  margin-bottom: 35px;
  text-align: center;
}
.nav--footer-secondary {
  text-align: center;
}

.nav--footer li {
  display: inline-block;
  padding: 0 0.5em;
}

.nav--footer-secondary li {
  display: inline-block;
  padding: 0 0.5em;
}

.nav--footer li,
.nav--footer-secondary li,
.page-footer__social li,
.page-footer__extra-links li {
  padding-left: 0;
  margin-bottom: 0;
}

.nav--footer li:before,
.nav--footer-secondary li:before,
.page-footer__social li:before,
.page-footer__extra-links li:before {
  display: none;
}

.nav--footer li:first-child {
  padding-left: 0;
}

.nav--footer a {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav--footer a:hover,
.nav--footer li.current-menu-item a {
  color: #848a98;
}

.page-footer__social {
  margin-bottom: 24px;
}

.page-footer__social li a {
  background: #d0d5de;
  color: #474d59;
}

.page-footer__social a:hover {
  background: #a8a8a8;
  color: #fff;
}

.page-footer__main .phone-number {
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
  margin-right: 0;
  text-align: center;
  padding: 8px 16px;
  background-color: #fc0;
  color: #272727;
}

.page-footer__main .phone-number:hover {
  color: #fff;
  background-color: #666;
}

.nav--footer-secondary {
  font-size: 13px;
  line-height: 1.538em;
}

.nav--footer-secondary li {
  padding-right: 20px;
  /* width: 30% */
}

.nav--footer-secondary a {
  color: #d0d5de;
}

.nav--footer-secondary a:hover,
.nav--footer-secondary li.current-menu-item a {
  color: #848a98;
}

.page-footer__copyright {
  color: #848a98;
  font-size: 13px;
  line-height: 1.65em;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
}

.page-footer__google-translate {
  display: inline-block;
}

.page-footer__extra-links {
  margin-bottom: 35px;
  text-align: center;
}

.page-footer__extra-links ul {
  display: inline-block;
}

.page-footer__extra-links li {
  display: inline-block;
  padding-left: 20px;
}

.page-footer__extra-links li > a {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.page-footer__extra-links li:first-child a:before {
  color: #fff;
  content: "\e604";
  font: 12px "icomoon";
  padding-right: 0.75em;
}

.page-footer__extra-links li:last-child a:before {
  color: #fff;
  content: "\f03b";
  font: 14px "icomoon";
  padding-right: 0.75em;
}

.page-footer__extra-links a:hover {
  color: #848a98;
}

.page-footer__chat {
  background: #5f646f;
  height: 50px;
  position: relative;
}

.chat-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
}

.chat {
  background: #fc0;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 700;
  padding: 0.75em 4em 0.75em 1.5em;
  text-align: right;
  text-transform: uppercase;
  width: 375px;
}

.chat:hover {
  background: #fcdb5f;
}

.chat-title {
  background: #1e1e1e;
  color: #fff;
  display: inline-block;
  left: 0;
  padding: 0.75em 1.5em;
  position: absolute;
  text-transform: capitalize;
  top: 0;
}

.chat-title:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 10px solid #1e1e1e;
}

.chat-hide {
  position: absolute;
  top: 0.75em;
  right: 1.5em;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background: #1e1e1e;
}

.chat-hide:hover {
  background: #0e0e0e;
}

#apexchat_invitation_container_minimized_wrapper,
#apexchat_invitation_container_wrapper,
#apexchat_tab_invitation_wrapper {
  display: none !important;
}

@media (min-width: 600px) {
  .page-footer__main .phone-number {
    display: block;
    margin: 0 auto;
    width: 60%;
  }
}

@media (min-width: 768px) {
  .page-footer {
    padding-bottom: 0;
  }

  .nav--footer {
    margin-bottom: 10px;
    text-align: left;
  }

  .nav--footer-secondary li {
    font-size: 13px;
  }

  .page-footer__copyright {
    text-align: left;
  }

  .page-footer__main .phone-number,
  .page-footer__extra-links {
    margin-bottom: 0;
  }

  .page-footer__main .phone-number {
    display: inline-block;
    margin: auto;
    width: auto;
  }

  .page-footer__extra-links {
    text-align: right;
  }

  .chat-wrapper {
    display: block;
    position: fixed;
    z-index: 99999;
  }
}

@media (min-width: 1025px) {
  .page-footer__main .phone-number {
    float: right;
    font-size: 14px;
    padding: 7px 29px;
  }

  .page-footer__social {
    float: left;
    margin-bottom: 20px;
  }

  .page-footer__social li,
  .page-footer__social a .page-footer__social li:last-child {
    padding-top: 3px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .page-footer__social li a {
    font-size: 14px;
    padding: 0 0.333em;
  }

  .chat-wrapper {
    position: fixed;
    z-index: 99999;
  }

  .page-footer__extra-links ul {
    float: right;
  }

  .nav--footer li {
    padding: 0 0.2em;
  }
}

@media (min-width: 1200px) {
  .nav--footer li {
    padding: 0 0.6em;
  }

  .page-footer__main .phone-number {
    padding: 7px 29px;
  }

  .page-footer__social li a {
    font-size: 18px;
    padding: 0.111em 0.333em;
  }
}

.mobile-footer-nav {
  background-color: #222;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  z-index: 999;
}

.mobile-footer-nav li {
  border-left: 1px solid #444;
  display: block;
  float: left;
  list-style: none;
  width: 33.3333%;
  padding-left: 0;
}

.mobile-footer-nav li:before {
  content: " ";
  display: none;
}

.mobile-footer-nav li:first-child {
  border-left: none;
}

.mobile-footer-nav a {
  color: #ccc;
  display: block;
  font-size: 12px;
  line-height: 1.2;
  padding: 10px;
}

.mobile-footer-nav span {
  display: block;
  height: 30px;
  color: #777;
  font-size: 24px;
}

.mobile-footer-nav .icon-login {
  font-size: 18px;
}

.product-stats-summary {
  font-size: 14px;
}

.product-stats-summary__row {
  font-family: sans-serif;
  line-height: 16px;
  margin-bottom: 0.588em;
}

.product-stats-summary {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.product-stats-summary dt {
  font-weight: 700;
}

.product-stats-summary dd {
  margin-left: 0;
}

#image-viewer {
  display: inline-block;
}

#image-viewer img {
  margin: auto;
  max-height: 400px;
}

.product__title {
  position: relative;
  z-index: 1;
}

.product__actions {
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  list-style: none !important;
  margin-top: 20px;
  padding-left: 0 !important;
  text-transform: uppercase;
}

.product__actions li {
  border-left: 1px solid #272727;
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
}

.product__actions li:first-child {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}

.product__actions a {
  color: #272727;
}

.single-cat_new_machine .button--primary .button--primary:hover,
.single-cat_new_machine .button--primary .button--primary {
  background: none;
  padding: 0;
  width: 100%;
}

.single-cat_new_machine .button--primary .button--primary:after {
  display: none;
}

.single .product-stats-summary__title {
  margin-top: 40px;
}

.single .product-stats-summary__row {
  float: left;
  width: 50%;
}

.media-tabs .tabs__nav {
  font-size: 24px;
}

.media-tabs-thumbnails.media-tabs-thumbnails {
  list-style: none !important;
  max-height: 100px;
  padding-left: 10%;
  padding-right: 10%;
  overflow: hidden;
}

.media-tabs-thumbnails .slick-list {
  margin: 0 auto;
}

.media-tabs-thumbnails .slick-prev:before,
.media-tabs-thumbnails .slick-next:before,
.featured-equipment .slick-prev:before,
.featured-equipment .slick-next:before {
  color: #333;
}

.media-tabs-thumbnails .slick-prev:hover:before,
.media-tabs-thumbnails .slick-next:hover:before,
.featured-equipment .slick-prev:before,
.featured-equipment .slick-next:before {
  color: #666;
}

.royalSlider .slick-prev:before,
.royalSlider .slick-next:before {
  font-size: 30px;
  opacity: 1;
  color: #000;
}

.royalSlider .slick-prev {
  left: 10px;
  z-index: 20;
}

.royalSlider .slick-next {
  right: 10px;
  z-index: 20;
}

.media-tabs-thumbnails .slick-prev,
.featured-equipment .slick-prev {
  left: 0;
}

.media-tabs-thumbnails .slick-next,
.featured-equipment .slick-next {
  right: 0;
}

.media-tabs-thumbnails .slick-slide,
.featured-equipment .slick-slide {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.media-tabs-thumbnails .slick-slide:hover,
.featured-equipment .slick-slide:hover {
  opacity: 0.6;
}

.media-tabs-thumbnails img {
  display: inline-block;
  height: auto;
  margin: 0 auto;
  max-width: 100px;
  max-height: 75px;
}

.media-tabs-thumbnails .product-detail__vpt img {
  float: none;
}

.media-tabs-thumbnails__label {
  display: block;
  margin: 0 10px;
}

.product__details,
.product__overview {
  font-size: 15px;
}

.product__details h4 {
  margin-top: 36px;
  margin-bottom: 24px;
}

.product__specs .specs__row {
  border-top: 1px solid #e4e4e4;
  font-size: 15px;
  padding: 5px 0;
}

.product__specs .specs__row:first-child {
  border-top: none;
}

.product__specs dt {
  font-weight: 700;
}

.product__specs dd {
  margin-left: 0;
}

.used-product__specs-column .used-cat-cert img {
  width: 70%;
  margin-bottom: 20px;
  max-width: 130px;
}

.used-price {
  font-size: 30px;
  font-weight: 400;
}

.product-detail__price dt {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
}

.used-product__specs-column {
  background: #f2f2f2;
  padding: 30px;
}

@media (min-width: 600px) {
  .single .product-stats-summary__row {
    float: none;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .single .product-stats-summary__title {
    margin-top: 80px;
  }

  .product__specs .specs__row {
    padding: 0;
  }

  .product-stats-summary__row:nth-child(2n + 1) {
    clear: left;
  }

  .product__specs dt,
  .product__specs dd {
    float: left;
    padding: 5px;
    width: 50%;
  }

  .product__specs dd {
    border-left: 1px solid #e4e4e4;
    padding-left: 30px;
  }
}

.customer-container {
  margin-left: 10px;
  margin-right: 10px;
}

.customer {
  background-color: #f2f2f2;
  border: 1px solid #e2e2e2;
}

.customer__media-container {
  position: relative;
  display: block;
}

.customer__media {
  display: block;
  width: 100%;
  height: auto;
}

.customer__media--img {
  width: 100%;
  max-width: 350px;
  height: auto;
}

.customer__media-container--quote:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 70px;
  height: 50px;
  background: url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/icon-quote.png) center center #464d58 no-repeat;
}

.customer__media-container--video:before,
.customer__media-container--video:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.customer__media-container--video:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 25px;
  border-color: transparent transparent transparent #fff;
  z-index: 1000;
}

.customer__media-container--video:hover:after {
  background-color: #fc0;
}

.customer__media-container--video:after {
  width: 70px;
  height: 50px;
  background-color: #464d58;
  border-radius: 15px;
  opacity: 0.85;
}

.customer__name {
  font-weight: 700;
  font-size: 20px;
  text-transform: none;
  color: #464d58;
}

.customer__details {
  padding: 40px 28px 35px;
  color: #464d58;
}

.customer-filter {
  margin-bottom: 30px;
}

.customer-filter__desc {
  float: left;
  margin-top: 8px;
  margin-right: 15px;
  font-weight: 700px;
  text-transform: uppercase;
  color: #464d58;
}

.selectric-customer-filter__select {
  float: left;
  max-width: 350px;
}

@media (min-width: 601px) {
  .customer-container {
    float: left;
    width: calc(50% - 20px);
  }
}

@media (min-width: 1025px) {
  .customer-container {
    width: calc(33% - 20px);
  }
}

input#popup_email {
  width: 44%;
  height: 44px;
  float: left;
}

.email-promo-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.email-promo-popup {
  position: relative;
  max-width: 645px;
  margin: 10% auto;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.email-promo-popup__header {
  font-weight: 700;
  padding: 40px 20px;
}

.email-promo-popup__header.heading-title.accent:after {
  border-width: 22px 40px 0 40px;
  bottom: -22px;
  margin-left: -40px;
}

.email-promo-popup__header h2 {
  color: #000;
  display: inline-block;
  font-size: 18px;
  line-height: 1em;
  margin: 0;
}

.email-promo-popup__headline-offer {
  border-top: 1px solid #000;
  display: block;
  font-size: 60px;
  font-weight: 900;
  line-height: 1em;
}

.email-promo-popup__content {
  padding: 40px 20px 10px 20px;
  text-align: center;
}

.email-promo-popup__close-link {
  color: gray;
  display: block;
  font-size: 14px;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: underline;
}

.email-promo-popup__close-link:hover {
  text-decoration: none;
}

@media (min-width: 600px) {
  .email-promo-popup__header {
    padding: 40px 50px;
  }

  .email-promo-popup__content {
    padding: 50px 50px 10px 50px;
  }

  .email-promo-popup__header h2 {
    line-height: 2em;
  }

  .email-promo-popup__headline-offer {
    font-size: 100px;
  }

  input#popup_email {
    width: 69%;
  }
}

@media (min-width: 768px) {
  .email-promo-popup__headline-offer {
    font-size: 132px;
  }
}

.homepage-body {
  padding-bottom: 0;
  padding-top: 0;
}

.division-button {
  padding-top: 20px;
}

.division-button__item {
  width: 100%;
}

.division-button__item:before {
  content: "";
  display: none;
}

.division-button__item span {
  background: #474d59;
  color: #fff;
  display: block;
  font-size: 17px;
  font-weight: 700;
  padding: 0.853em 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.division-button__item:nth-child(even) span {
  background: #353a45;
}

.division-button__item span:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 21.5px 0 21.5px;
  border-color: #474d59 transparent transparent transparent;
  margin-left: -22px;
}

.division-button__item:nth-child(even) span:after {
  border-color: #353a45 transparent transparent transparent;
}

.division-button__item img {
  margin: 0 auto;
}

.homepage-body .page-article {
  background: #e9e9e9;
  width: 66.66666667%;
  float: left;
}

.homepage-body .page-article .heading-title {
  font-weight: 600;
}

.homepage-body--content {
  padding: 3.25em 2.875em;
  position: relative;
}

.homepage-body--content p:last-child {
  margin-bottom: 0;
}

.homepage-body--content img {
  bottom: 0;
  margin: 0;
  position: absolute;
  right: -28px;
}

.homepage-body .page-sidebar {
  margin-bottom: 5em;
}

.homepage-body .page-sidebar .heading-title {
  font: 700 20px "Source Sans Pro";
  padding: 0.87em;
}

.homepage-body .page-sidebar--content {
  padding-top: 2em;
}

.homepage-body .page-sidebar--content h6 {
  padding-bottom: 6px;
  text-transform: none;
}

.homepage-body .page-sidebar--content h6:before {
  padding-right: 7px;
}

.homepage-body .page-sidebar--content ul {
  font-size: 14px;
}

.homepage-body .page-sidebar--content b {
  font-size: 13px;
}

.homepage-body .page-sidebar--content p {
  margin-bottom: 0.5em;
}

.homepage-body .page-sidebar--content {
  padding: 3.375em 2.813em 1em;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .homepage-body .page-sidebar .heading-title {
    padding: 0.875em;
  }
}

@media (min-width: 600px) {
  .division-button__item {
    float: left;
    width: 50%;
  }

  .homepage-body .page-sidebar {
    margin-bottom: 0;
  }

  .homepage-body .page-sidebar--content {
    background: #f7f7f7;
    padding: 3.375em 2.813em 1em;
    padding-bottom: 4em;
  }

  .homepage-body .page-sidebar--content p {
    float: left;
    margin-right: 4%;
    width: 48%;
  }

  .homepage-body .page-sidebar--content ul {
    float: left;
    width: 48%;
  }

  .homepage-body .page-sidebar--content .button {
    float: left;
    margin-top: 2em;
  }
}

@media (min-width: 768px) {
  .division-button {
    padding-top: 50px;
  }

  .division-button__item {
    width: 33.3333%;
  }
}

@media (min-width: 1025px) {
  .homepage-wrapper {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .division-button {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .division-button__item {
    width: 16.6667%;
  }

  .division-button__item span:after {
    bottom: 0;
  }

  .division-button__item:hover span:after {
    bottom: -12px;
  }

  .homepage-body [class*="col-"] {
    padding: 0;
  }

  .homepage-body .page-sidebar--content {
    background: none;
  }

  .homepage-body .page-sidebar--content p,
  .homepage-body .page-sidebar--content ul {
    float: none;
    margin-right: 0;
    width: 100%;
  }

  .homepage-body .page-sidebar--content .button {
    margin-top: 1em;
  }

  .homepage-body--content p {
    font-size: 14px;
    max-width: 60%;
  }

  .homepage-body--content p:first-of-type {
    font-size: 16px;
    max-width: 100%;
  }

  .homepage-body .page-sidebar {
    width: 33.33333333%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .homepage-body--content p {
    max-width: 65%;
  }
}

@media (min-width: 1366px) {
  .division-button {
    position: relative;
  }

  .division-button ul {
    position: relative;
    z-index: 1;
  }

  .division-button:before,
  .division-button:after {
    content: "";
    height: 54px;
    position: absolute;
    top: 0;
    width: 500px;
  }

  .division-button:before {
    background: #474d59;
    left: 0;
  }

  .division-button:after {
    background: #353a45;
    right: 0;
  }
}

.filter-options--location-page {
  margin-top: 43px;
}

.locations-listings__state {
  margin-top: 50px;
}

.locations-map {
  height: 600px;
}

.map-marker a {
  color: #000;
}

img.map-marker--active {
  position: relative;
  top: 10px;
  padding-right: 10px;
}

.nearest--location h6:before {
  padding-right: 20px;
}

.nearest-location--numbers {
  font-size: 14px;
}

.location-page .page-sidebar .heading-title.accent {
  color: #222;
  background: #fc0;
}

.location-page .page-sidebar .heading-title.accent:after {
  border-color: #fc0 transparent transparent transparent !important;
}

.ems-level {
  min-height: 220px;
}

.ems-level h6 {
  padding-top: 7%;
}

@media (max-width: 768px) {
  .ems-level img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .ems-level h6 {
    padding-top: 0;
  }
}

@media (max-width: 600px) {
  .ems-level img {
    width: 100%;
    height: auto;
  }
}

.slideshow .royalSlider {
  margin-bottom: 0;
  width: 100%;
}

.slideshow .slick-slide {
  position: relative;
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  opacity: 0.3;
  transition: all 0.5s ease;
}

.slideshow .slick-slide.slick-current {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.slideshow .contain--wide {
  left: 30%;
  position: absolute;
  top: 155px;
}

.slideshow .headline-container {
  background: rgba(255, 255, 255);
  background: rgba(255, 255, 255, 0.9);
  width: 345px;
}

.slideshow .headline-container h1,
.slideshow .headline-container p {
  margin-bottom: 0;
}

.slideshow .headline-container h1 {
  font: 600 32px/1.098em "Inter", sans-serif;
  padding: 25px;
}

.slideshow .headline-container h1 b,
.slideshow .headline-container h1 strong {
  font-weight: 900;
}

.slideshow .headline-container .button--tertiary {
  font: 17px "Inter", sans-serif;
  padding: 0.706em 2.059em;
  text-transform: none;
}

.slideshow .headline-container .button--block {
  width: 100%;
}

@media (min-width: 768px) {
  .slideshow .headline-container {
    width: 400px;
  }
}

@media (min-width: 1025px) {
  .slideshow .headline-container {
    width: 450px;
  }

  .slideshow .headline-container h1 {
    font: 600 49px/1.098em "Inter", sans-serif;
    padding: 33px;
  }

  .slideshow .contain--wide {
    left: 22%;
  }
}

.sc-slideshow {
  position: relative;
  max-height: 600px;
  margin-top: 156px;
  overflow: hidden;
}

.sc-slideshow .slick-slider {
  margin-bottom: 0;
}

.sc-slideshow .contain--wide {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sc-slideshow .slick-prev,
.sc-slideshow .slick-next {
  z-index: 100;
}

.sc-slideshow .slick-prev {
  left: 10px;
}

.sc-slideshow .slick-next {
  right: 10px;
}

.sc__category-banner {
  position: relative;
  margin-bottom: 50px;
}

.sc__category-banner:before {
  content: "";
  width: 1000%;
  height: 75px;
  background-color: #353a45;
  top: 0;
  left: -50%;
  position: absolute;
}

.sc__categories {
  display: table;
  width: 100%;
  max-width: 1366px;
  margin-bottom: 15px;
  overflow: auto;
  table-layout: fixed;
}

.sc__category-btn {
  position: relative;
  float: none;
  display: table-cell;
  vertical-align: top;
  overflow: hidden;
  width: auto;
}

.sc__category-link {
  display: block;
}

.sc__category-image-sublink-container {
  position: relative;
}

.sc__category-btn span {
  display: table-cell;
  vertical-align: middle;
  width: 11.111111111%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 16px;
  height: 75px;
}

.sc__category-btn:hover span {
  background: #4d5460;
}

.sc__category-btn:hover span:after {
  border-top-color: #4d5460;
}

.sc__category-btn:nth-child(even):hover span {
  background: #2f333d;
}

.sc__category-btn:nth-child(even):hover span:after {
  border-top-color: #2f333d;
}

.sc__categories:before,
.sc__categories:after {
  display: none;
}

.sc__category-btn img {
  opacity: 0.85;
  transition: all 0.25s ease;
}

.sc__category-btn:hover img {
  opacity: 1;
}

.sc__category-btn span:after {
  transition: all 0.3s ease;
  z-index: 10;
}

.sc-slideshow .slick-slide {
  height: auto;
}

.sc__category-banner--truck .sc__categories {
  text-align: left;
  background-color: #e9e9e9;
  margin-bottom: 25px;
}

.sc__category-banner--truck:before {
  width: 100%;
}

.sc__category-banner--truck .sc__category-btn:not(:first-of-type):after {
  display: none;
}

.sc__category-banner--truck .sc__category-btn img {
  opacity: 1;
}

.sc__category-banner--truck .sc__category-button span {
  padding-top: 15px;
  padding-bottom: 15px;
}

.sc__category-button {
  padding: 0;
  background-color: transparent;
}

.sc__category-content {
  position: relative;
  overflow: hidden;
}

.sc__category-content:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  opacity: 0;
  transition: all 0.3s cubic-bezier(1, 0, 0.25, 1);
  z-index: 1;
}

.division-button__item:nth-of-type(even) .sc__category-content:before {
  background-color: #e9e9e9;
}

.clicked .sc__category-content:before {
  top: 0;
  opacity: 0.9;
}

.has-sublinks:hover .sc__sublinks {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.has-sublinks:hover .sc__category-image {
  opacity: 0.25;
}

ul.sc__sublinks {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  transition: all 0.3s cubic-bezier(1, 0, 0.25, 1);
  z-index: 1000;
  width: 100%;
  text-align: center;
}

.clicked .sc__sublinks {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sc__sublink {
  padding-left: 0;
  margin-bottom: 0;
}

.sc__sublink:before {
  display: none;
  content: "";
}

.sc__sublink a {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #272727;
  padding: 10px 15px;
}

.sc__sublink a:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.sc__title {
  background-color: #b11519;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.sc__title:after {
  border-top-color: #b11519 !important;
}

.sc__title.black:after {
  border-top-color: #222 !important;
}

.sc__leading-text,
.sc__supporting-text {
  background-color: #e9e9e9;
}

.sc__leading-text {
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 18px;
  line-height: 28px;
  overflow: auto;
}

.sc__supporting-text {
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 26px;
}

.sc__quoteblock {
  float: right;
  width: 350px;
  border-left: 3px solid #c4c4c4;
  margin-left: 40px;
  padding-left: 20px;
  margin-right: 50px;
}

.sc__quote-text {
  font-size: 20px;
  font-weight: 700;
}

.sc__quote:nth-of-type(2) {
  margin-top: 10px;
}

.sc__quote-name {
  font-size: 14px;
  margin-top: 5px;
}

.sc__videos {
  margin-top: 50px;
}

.sc__video-btn {
  text-align: center;
  display: block;
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
}

.sc__video-btn:after {
  content: url(https://d3leeb4r1qy96s.cloudfront.net/assets/img/play-icon.png);
  width: 107px;
  height: 81px;
  left: 0;
  right: 0;
  margin: auto;
  top: 70px;
  position: absolute;
}

.sc__video-btn-container {
  height: 200px;
  display: block;
  position: relative;
  overflow: hidden;
}

.sc__video-img {
  width: auto;
  height: 275px;
  position: absolute;
  top: -40px;
  left: -50px;
}

.sc__video-title {
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.85);
  background: #474d59;
  display: block;
  text-transform: uppercase;
  padding: 15px 25px;
  float: left;
  width: 100%;
  transition: all 0.3s linear;
}

.sc__video-title:hover {
  color: #fff;
  background-color: #333740;
}

.quick-links-container {
  max-width: 1366px;
}

.quick-links {
  position: absolute;
  top: 420px;
  left: 66.666666667%;
  width: 300px;
  z-index: 50;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.quick-links__sidebar {
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: 35px;
  width: 100%;
  -webkit-transform: none;
  transform: none;
}

.quick-links__topper {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  background-color: #b11519;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  text-transform: uppercase;
  position: relative;
}

.quick-links__topper.black {
  background-color: #222;
}

.quick-links__topper.black:after {
  border-top-color: #222;
}

.quick-links__topper:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 31px 0 31px;
  border-color: #b11519 transparent transparent transparent;
  position: absolute;
  bottom: -15px;
  margin-left: -31px;
  left: 50%;
}

.quick-links__primary {
  background-color: #f4c302;
  color: #000;
  padding-top: 10px;
}

.quick-links__secondary {
  background-color: #f2f2f2;
}

.quick-links__primary,
.quick-links__secondary {
  overflow: auto;
}

.quick-link--primary,
.quick-link--secondary {
  width: 100%;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0;
  opacity: 0.85;
  transition: all 0.3s ease;
  position: relative;
}

.quick-link--primary:hover,
.quick-link--secondary:hover {
  opacity: 1;
}

.quick-link--primary:not(:last-of-type):after,
.quick-link--secondary:not(:last-of-type):after {
  content: "";
  width: 85%;
  height: 1px;
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.75;
}

.quick-link--primary:after {
  background-color: #897010;
}

.quick-link--secondary:after {
  background-color: #cbcbcb;
}

.quick-link--primary:hover span,
.quick-link--secondary:hover span {
  padding-left: 3px;
}

.quick-link--primary span,
.quick-link--secondary span,
.quick-link--primary__icon,
.quick-link--secondary__icon {
  display: inline-block;
  vertical-align: middle;
  color: #000 !important;
}

.quick-link--primary__icon,
.quick-link--secondary__icon {
  margin-left: 30px;
}

.quick-link--primary span,
.quick-link--secondary span {
  margin-left: 15px;
  max-width: 65%;
}

.quick-link--secondary__icon {
  width: 25px;
  height: auto;
  margin-left: 30px;
}

.quick-link--primary span {
  width: 65%;
  font-size: 16px;
  line-height: 18px;
}

.quick-link--secondary span {
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: 1366px) {
  .sc-slideshow:not(.rental-slideshow) .contain--wide {
    left: 10%;
  }
}

@media (max-width: 1200px) {
  .sc__category-btn:not(:first-of-type):after {
    height: 45px;
  }

  .sc-slideshow:not(.rental-slideshow) .contain--wide {
    left: 7%;
  }
}

@media (max-width: 1130px) {
  .sc__category-btn span {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (max-width: 1024px) {
  .sc-slideshow {
    top: 0;
    margin-top: 0;
  }

  .sc-slideshow:not(.rental-slideshow) .contain--wide {
    left: 1%;
  }

  .quick-links {
    left: auto;
    right: 1%;
  }

  .sc__categories {
    display: block;
    padding-top: 0;
  }

  .sc__category-banner:before {
    display: none;
  }

  .sc__category-btn {
    float: left;
    display: block;
    width: 33.33333333%;
    background-color: #fff;
  }

  .sc__category-btn:after {
    display: none;
  }

  .sc__category-btn span {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    padding-top: 10%;
  }

  .sc__videos {
    margin-bottom: 50px;
  }

  .page-rental-sidebar .widget-1 img {
    display: block;
    margin: auto;
  }
}

.rental-slideshow img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  max-width: unset;
}

@media (min-width: 1200px) {
  .rental-slideshow .slick-slide {
    height: 600px;
  }
}

@media (max-width: 767px) {
  .sc__supporting-text {
    clear: both;
  }

  .sc__quoteblock {
    padding: 25px 50px;
    background-color: #e9e9e9;
    width: 100%;
    float: none;
    border-left: none;
    margin: 0;
  }

  .sc__quote {
    padding: 25px 0;
    border-top: 3px solid #c4c4c4;
    border-bottom: 3px solid #c4c4c4;
  }

  .sc__video-img {
    width: 100%;
    height: 275px;
    position: absolute;
    top: -40px;
    left: 0;
  }

  .sc__video-title {
    height: 80px;
  }

  .sc-slider {
    display: none;
  }

  .sc-slideshow {
    max-height: none;
  }

  .quick-links {
    -webkit-transform: none;
    transform: none;
  }

  .rental-slideshow .contain--wide {
    position: relative;
    -webkit-transform: none;
    transform: none;
    top: 0;
    left: 0;
  }

  .rental-slideshow .headline-container {
    width: auto;
    padding: 30px;
  }

  .rental-slideshow .headline-container h1 {
    padding-left: 0;
    padding-right: 0;
  }

  .rental-slideshow.headline-container span {
    font-size: inherit !important;
  }

  .rental-slideshow .headline-container .button--block {
    display: inline-block;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
  }

  .rental-slideshow .headline-container .button--tertiary:after {
    border-width: 22px 0 22px 13px;
  }

  .rental-slideshow .slick-prev,
  .rental-slideshow .slick-next {
    bottom: 20px;
    top: auto;
  }

  .rental-slideshow .slick-prev {
    left: 80px;
  }

  .rental-slideshow .slick-next {
    right: auto;
    left: 60px;
  }
}

@media (max-width: 600px) {
  .sc__category-banner--truck .sc__category-button span {
    height: auto;
  }

  .sc__category-btn span {
    font-size: 12px;
    line-height: 14px;
    height: 60px;
  }

  .sc__quote {
    padding: 10px 0;
  }

  .sc__title {
    font-size: 24px;
    line-height: 30px;
  }

  .sc__leading-text,
  .sc__supporting-text,
  .sc__quote-text {
    font-size: 16px;
    line-height: 1.613em;
  }

  .sc__leading-text,
  .sc__supporting-text,
  .sc__quoteblock {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }

  .sc__video-title {
    height: auto;
  }

  .sc__videos {
    margin-top: 0;
  }

  .sc__video-btn-container {
    height: 135px;
  }

  .sc__video-img {
    width: 100%;
    height: auto;
  }

  .sc__video-btn:after {
    top: 25px;
  }
}

@media (max-width: 1099px) {
  .quick-links__hp {
    position: inherit;
    left: unset;
    top: unset;
    width: 100%;
    right: unset;
    transform: none;
  }
}

ul.error-page {
  list-style: none;
  padding-left: 0;
}

.button-image--name:hover {
  background-color: #1c1c1c;
}

.search-results .post {
  padding: 20px;
  transition: all 0.3s ease;
}

.search-results .post:hover {
  background: #e2e2e2;
}

.login-register {
  margin-bottom: 50px;
}

.login-register__section--register {
  background: #fc0;
}

.login-register__section--login {
  background: #e9e9e9;
}

.login-register__section--login span {
  font-size: 20px;
  margin-right: 10px;
}

.login-register__content {
  padding: 30px 20px;
}

@media (min-width: 600px) {
  .login-register {
    display: table;
    width: 100%;
  }

  .login-register__section {
    display: table-cell;
    width: 50%;
  }

  .login-register__content {
    padding: 50px;
  }
}

.product.product-mini {
  display: none;
}

@media (max-width: 767px) {
  .position {
    margin-bottom: 20px;
  }
}

.rep-bio {
  font-size: 14px;
}

.equipment-search-wrap--background {
  background-color: #f2f2f2;
}

.js-search-form {
  display: block;
}

.js-search-form.active {
  display: block;
}

.js-equipment-search-title {
  margin-bottom: 0;
}

@media (min-width: 600px) {
  .js-search-form {
    display: block;
  }
}

@media (min-width: 768px) {
  .product-filter {
    display: inline-flex;
  }

  .product-filter label {
    padding-right: 20px;
    position: relative;
    top: 13px;
    width: 110px;
  }

  input#search {
    width: 60%;
    height: 45px;
    position: relative;
    top: 0;
    float: left;
  }
}

@media (min-width: 1025px) {
  .equipment-search-wrap {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 25%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-sidebar.has-equipment-search {
    padding-top: 860px;
  }

  .product-filter {
    float: right;
  }

  .js-search-form button[type="submit"] {
    padding: 13px 14px 14px 18px;
  }

  .quick-search-control button[type="submit"] {
    padding: 15px 26px 15px 30px;
  }
}

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  left: 0;
  width: 11%;
}

.irs-line-mid {
  left: 9%;
  width: 82%;
}

.irs-line-right {
  right: 0;
  width: 11%;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
}

.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.irs-disabled {
  opacity: 0.4;
}

.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40);
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs {
  height: 55px;
}

.irs-with-grid {
  height: 75px;
}

.irs-line {
  height: 10px;
  top: 33px;
  background: #e3e3e3;
  border: 1px solid #b3b3b3;
  border-radius: 16px;
  -moz-border-radius: 16px;
}

.irs-line-left {
  height: 8px;
}

.irs-line-mid {
  height: 8px;
}

.irs-line-right {
  height: 8px;
}

.irs-bar {
  height: 10px;
  top: 33px;
  border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  background: #cecece;
}

.irs-bar-edge {
  height: 10px;
  top: 33px;
  width: 14px;
  border: 1px solid #428bca;
  border-right: 0;
  background: #428bca;
  background: linear-gradient(to top, rgba(66, 139, 202, 1) 0%, rgba(127, 195, 232, 1) 100%);
  border-radius: 16px 0 0 16px;
  -moz-border-radius: 16px 0 0 16px;
}

.irs-shadow {
  height: 2px;
  top: 38px;
  background: #000;
  opacity: 0.3;
  border-radius: 5px;
  -moz-border-radius: 5px;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=30);
}

.irs-slider {
  top: 27px;
  width: 20px;
  height: 20px;
  border: 1px solid #b3b3b3;
  background: #cecece;
  border-radius: 20px;
  -moz-border-radius: 27px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.irs-slider.state_hover,
.irs-slider:hover {
  background: #fff;
}

.irs-min,
.irs-max {
  color: #333;
  font-size: 12px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  display: none;
}

.lt-ie9 .irs-min,
.lt-ie9 .irs-max {
  background: #ccc;
}

.irs-from,
.irs-to,
.irs-single {
  color: #656565;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
  text-shadow: none;
  padding: 1px 5px;
  font-weight: 600;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #000;
}

.lt-ie9 .irs-from,
.lt-ie9 .irs-to,
.lt-ie9 .irs-single {
  background: #999;
}

.irs-grid {
  height: 27px;
}

.irs-grid-pol {
  opacity: 0.5;
  background: #656565;
}

.irs-grid-pol.small {
  background: #656565;
  display: none;
}

.irs-grid-text {
  bottom: 5px;
  color: #656565;
}

@-ms-viewport {
  width: device-width;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px !important;
}

.img-circle {
  border-radius: 50% !important;
}

.img-square {
  border-radius: 0 !important;
}

.show {
  display: block !important;
}

.visible-xxs,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 599px) {
  .visible-xxs {
    display: block !important;
  }

  table.visible-xxs {
    display: table;
  }

  tr.visible-xxs {
    display: table-row !important;
  }

  th.visible-xxs,
  td.visible-xxs {
    display: table-cell !important;
  }

  .visible-xxs-block {
    display: block !important;
  }

  .visible-xxs-inline {
    display: inline !important;
  }

  .visible-xxs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

.hide {
  display: none !important;
}

/* .hidden {
  display: none ;
  visibility: hidden;
} */
/* 
.invisible {
  visibility: hidden !important;
} */

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@media (max-width: 599px) {
  .hidden-xxs {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.visible-print,
.visible-print-block,
.visible-print-inline,
.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }

  .visible-print-block {
    display: block !important;
  }

  .visible-print-inline {
    display: inline !important;
  }

  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.position--relative {
  position: relative !important;
}

.position--absolute {
  position: absolute !important;
}

.position--fixed {
  position: fixed !important;
}

.position--static {
  position: static !important;
}

.clear--left {
  clear: left !important;
}

.clear--right {
  clear: right !important;
}

.clear--both {
  clear: both !important;
}

.clear--contain {
  overflow: hidden !important;
}

.text--left {
  text-align: left !important;
}

.text--center {
  text-align: center !important;
}

.text--right {
  text-align: right !important;
}

.weight--light {
  font-weight: 300 !important;
}

.weight--normal {
  font-weight: 400 !important;
}

.weight--semibold {
  font-weight: 600 !important;
}

.weight--bold {
  font-weight: 700 !important;
}

.border--top {
  border-top: 1px solid #dbdbdb;
}

.border--bottom {
  border-bottom: 1px solid #dbdbdb;
}

.border--left {
  border-left: 1px solid #dbdbdb;
}

.border--right {
  border-right: 1px solid #dbdbdb;
}

.border--all {
  border: 1px solid #dbdbdb;
}

.push {
  margin: 20px !important;
}

.push--top {
  margin-top: 20px !important;
}

.push--right {
  margin-right: 20px !important;
}

.push--bottom {
  margin-bottom: 20px !important;
}

.push--left {
  margin-left: 20px !important;
}

.push--ends {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.push--sides {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.push-half {
  margin: 10px !important;
}

.push-half--top {
  margin-top: 10px !important;
}

.push-half--right {
  margin-right: 10px !important;
}

.push-half--bottom {
  margin-bottom: 10px !important;
}

.push-half--left {
  margin-left: 10px !important;
}

.push-half--ends {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.push-half--sides {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.push-double {
  margin: 40px !important;
}

.push-double--top {
  margin-top: 40px !important;
}

.push-double--right {
  margin-right: 40px !important;
}

.push-double--bottom {
  margin-bottom: 40px !important;
}

.push-double--left {
  margin-left: 40px !important;
}

.push-double--ends {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.push-double--sides {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.flush {
  margin: 0 !important;
}

.flush--top {
  margin-top: 0 !important;
}

.flush--right {
  margin-right: 0 !important;
}

.flush--bottom {
  margin-bottom: 0 !important;
}

.flush--left {
  margin-left: 0 !important;
}

.flush--ends {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.flush--sides {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.soft {
  padding: 20px !important;
}

.soft--top {
  padding-top: 20px !important;
}

.soft--right {
  padding-right: 20px !important;
}

.soft--bottom {
  padding-bottom: 20px !important;
}

.soft--left {
  padding-left: 20px !important;
}

.soft--ends {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.soft--sides {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.soft-double {
  padding: 40px !important;
}

.soft-double--top {
  padding-top: 40px !important;
}

.soft-double--right {
  padding-right: 40px !important;
}

.soft-double--bottom {
  padding-bottom: 40px !important;
}

.soft-double--left {
  padding-left: 40px !important;
}

.soft-double--ends {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.soft-double--sides {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.soft-half {
  padding: 10px !important;
}

.soft-half--top {
  padding-top: 10px !important;
}

.soft-half--right {
  padding-right: 10px !important;
}

.soft-half--bottom {
  padding-bottom: 10px !important;
}

.soft-half--left {
  padding-left: 10px !important;
}

.soft-half--ends {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.soft-half--sides {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.hard {
  padding: 0 !important;
}

.hard--top {
  padding-top: 0 !important;
}

.hard--right {
  padding-right: 0 !important;
}

.hard--bottom {
  padding-bottom: 0 !important;
}

.hard--left {
  padding-left: 0 !important;
}

.hard--ends {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hard--sides {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.affix--top {
  position: fixed !important;
  top: 0 !important;
}

.affix--bottom {
  position: fixed !important;
  bottom: 0 !important;
}

.affix--left {
  position: fixed !important;
  left: 0 !important;
}

.affix--right {
  position: fixed !important;
  right: 0 !important;
}

.pull--right {
  float: right !important;
}

.pull--left {
  float: left !important;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mobile-only {
  display: block;
}

.desktop-only {
  display: block !important;
}

@media (max-width: 1024px) {
  .desktop-only {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .mobile-only {
    display: none !important;
  }
}

.widget_archive label {
  visibility: hidden;
}

.page-sidebar .widget_easy_sidebar_menu_widget {
  background: #f2f2f2;
  padding: 20px 15px;
  margin-bottom: 2.25em;
  padding-bottom: 1em;
}

.page-sidebar .widget_easy_sidebar_menu_widget ul {
  list-style: none;
}

.page-sidebar .widget_easy_sidebar_menu_widget .link__wrap a {
  color: #1c1c1c;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
  text-transform: uppercase;
}

.widget_easy_sidebar_menu_widget li.menu-item .sub-menu .link__wrap a {
  font-size: 16px;
  font-weight: 600;
  text-transform: none !important;
}

.page-sidebar .widget_easy_sidebar_menu_widget li.menu-item .easy-sidebar-menu-widget-toggler i:hover:before {
  color: #202020;
}

@media (min-width: 1025px) {
  .widget_easy_sidebar_menu_widget li.menu-item .sub-menu {
    width: 230px;
  }
}

@media (max-width: 1080px) {
  #attachment_90308,
  #attachment_90310,
  #attachment_90309,
  #attachment_90308 img,
  #attachment_90310 img,
  #attachment_90309 img,
  img.wp-image-96880 {
    width: 100% !important;
    -o-object-fit: contain !important;
    object-fit: contain !important;
    height: auto !important;
  }
}

.app-sidebar-banner-container .textwidget {
  padding: 0;
}

.app-sidebar-banner {
  position: relative;
  display: block;
  height: 160px;
  width: 100%;
}

.app-sidebar-banner_image {
  height: 100%;
  width: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top left;
  object-position: top left;
  z-index: 1;
}

.app-sidebar-banner_overlay {
  z-index: 2;
  width: 100%;
  height: 160px;
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(15deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
}

.app-sidebar-banner_content {
  z-index: 3;
  width: 100%;
  height: 160px;
  position: absolute;
  display: flex;
  padding: 10px;
}

.app-sidebar-banner_content p {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin-top: 40px;
  transition: ease-in-out 0.3s;
  text-transform: uppercase;
}

.app-sidebar-banner:hover p {
  color: #ffcd11;
}

.app-sidebar-banner_content p:before {
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  margin-bottom: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}

body.search-results .page-content .post:first-of-type {
  padding-top: 20px !important;
}

.background-image-wrapper {
  position: relative;
  height: 225px;
  /* width: 100% */
}

img.homepage-masthead-bg {
  /* height: 100%;
    width: 100%; */
  object-fit: cover;
  -o-object-fit: cover;
  font-family: "object-fit:cover;";
  max-height: 800px;
}

.background-image-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  /* border-left: 100vw solid transparent; */
  /* border-right: 75px solid transparent;
    border-bottom: 40px solid #272727; */
  display: block;
  position: absolute;
  bottom: 0;
}

.hp-slide-content {
  padding-top: 35px;
}

h2.h2.hp-mast-subheading {
  font-size: 22px;
  color: #fc0;
  padding-bottom: 8px;
}

h2.h1.hp-mast-heading {
  font-size: 42px;
  color: #fff;
  line-height: 1em;
}

@media (min-width: 768px) {
  .background-image-wrapper {
    position: absolute;
    height: 100%;
  }

  /* .background-image-wrapper:before {
        content: "";
        height: 100%;
        width: 100%;
        background-image: linear-gradient(45deg,rgba(0,0,0,.6),rgba(0,0,0,.5));
        position: absolute;
        top: 0;
        left: 0
    } */

  .background-image-wrapper:after {
    border-bottom: 80px solid #e8e9e8;
    bottom: -1px;
  }

  .container.hp-slide-content {
    padding-top: 50px;
    padding-bottom: 200px;
  }

  .js-masthead-homepage-slider {
    display: none;
  }

  .js-masthead-homepage-slider.slick-slider {
    display: block;
  }

  .js-masthead-homepage-slider .slick-dots {
    bottom: 350px;
  }

  h2.h2.hp-mast-subheading {
    font-size: 26px;
  }

  h2.h1.hp-mast-heading {
    font-size: 56px;
  }
}

@media (min-width: 1025px) {
  .container.hp-slide-content {
    padding-top: 70px;
    padding-bottom: 300px;
  }

  h2.h2.hp-mast-subheading {
    font-size: 30px;
  }

  h2.h1.hp-mast-heading {
    font-size: 65px;
  }

  .background-image-wrapper:after {
    bottom: -1px;
  }
}

@media (max-width: 767px) {
  .js-masthead-homepage-slider {
    max-height: 225px;
  }
}

.wpcm_closest_location h6,
.wpcm_closest_location .h6 {
  font-family: "Inter", sans-serif !important;
}

.service-card-with-featured-image {
  background-color: #f2f2f2;
  padding: 50px 0;
}

@media (min-width: 768px) {
  .service-card-with-featured-image__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -16px -32px;
  }
}

.service-card-with-featured-image__item {
  width: 100%;
}

@media (max-width: 767px) {
  .service-card-with-featured-image__item:not(:last-child) {
    margin-bottom: 32px;
  }

  .service-card-with-featured-image__item-2:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media (min-width: 768px) {
  .service-card-with-featured-image__item {
    width: 50%;
    padding: 0 16px;
    margin-bottom: 32px;
  }

  .service-card-with-featured-image__item-2 {
    width: 50%;
    padding: 0 16px;
    margin-bottom: 32px;
  }
}

@media (min-width: 1200px) {
  .service-card-with-featured-image__item {
    width: 33%;
  }

  .service-card-with-featured-image__item-2 {
    width: 63.7%;
  }
}

.service-card-with-featured-image__link {
  display: block;
  height: 100%;
  position: relative;
}

@media (min-width: 1200px) {
  .service-card-with-featured-image__link:hover .service-card-with-featured-image__content .icon span {
    width: 80px;
  }

  .service-card-with-featured-image__link:hover .service-card-with-featured-image__image img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.service-card-with-featured-image__card {
  height: 100%;
  display: block;
  color: #1c1c1c;
  background-color: #fff;
  text-decoration: none;
  position: relative;
}

.service-card-with-featured-image h3 {
  text-align: center;
  text-transform: capitalize;
}

.service-card-with-featured-image__image {
  padding-top: 66.65%;
  position: relative;
  overflow: hidden;
}

.service-card-with-featured-image__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.service-card-with-featured-image__content {
  padding: 20px 20px 60px;
}

.service-card-with-featured-image__content p {
  margin-top: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0;
  line-height: 1.4em;
  font-size: 16px;
  color: #1c1c1c;
}

.service-card-with-featured-image__content .icon {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: 46px;
}

.service-card-with-featured-image__content .icon:before {
  content: "";
  display: block;
  width: 20px;
  height: 46px;
  position: absolute;
  background-color: #fc0;
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
  left: -4px;
}

.service-card-with-featured-image__content .icon span {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: #fc0;
  transition: width 0.3s ease-in-out;
}

.service-card-with-featured-image__content .icon span:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 0;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #272727;
}

.gray-bg {
  background: #f2f2f2 !important;
}

.white-bg {
  background: #fff !important;
}

.wysiwyg-section {
  padding: 50px 0;
}

.wysiwyg-section .button--secondary {
  background: #1a1a1a;
}

.wysiwyg-section .button--secondary:after {
  background: #1a1a1a;
}

.wysiwyg-section .button--secondary:hover {
  background: #484c57;
  color: #fff;
}

.wysiwyg-section .button--secondary:hover:after {
  background: #484c57;
}

.wysiwyg-section .button {
  margin-bottom: 25px;
}

.button + .button {
  margin-top: 15px;
}

.wysiwyg-section p:last-child,
.text-content p:last-child {
  padding-bottom: 0 !important;
}

@media (min-width: 375px) {
  .button + .button {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .wysiwyg-section {
    padding: 90px 0;
  }

  .wysiwyg-section p {
    padding-bottom: 25px;
  }

  .wysiwyg-section .container {
    max-width: 1024px;
  }

  .button + .button {
    margin-left: 15px;
  }
}

:target {
  scroll-margin-top: 50px;
}

@media (min-width: 1024px) {
  :target {
    scroll-margin-top: 135px;
  }

  .page-header-is-sticky :target {
    scroll-margin-top: 120px;
  }
}

.fx-table-responsive {
  margin: 20px 0;
  overflow-x: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
}

.fx-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.fx-table th,
.fx-table td {
  white-space: nowrap;
  text-align: center;
}

.fx-table th {
  padding: 25px;
}

.fx-table td {
  padding: 15px 25px;
}

.fx-table thead {
  background-color: #ffcd11;
}

.fx-table tbody tr:nth-child(odd) {
  background-color: rgb(0 0 0 / 10%);
}

@media (min-width: 1200px) {
  .fx-table th {
    padding: 5px;
  }

  .fx-table td {
    padding: 5px 15px;
  }

  .fx-table th,
  .fx-table td {
    white-space: inherit;
  }
}

.page-id-120119 .masthead {
  background: #fff;
}

.page-id-120119 .masthead-background,
.page-id-120119 .breadcrumbs {
  display: none !important;
}

@media (min-width: 768px) {
  .page-id-120119 .image-left-text-right {
    padding-bottom: 0 !important;
  }
}

ul li:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fc0;
}

.call-action-bar-section {
  padding: 35px 0;
  background: #1a1a1a;
}

.header-text h3 {
  color: #fc0;
}

.header__lower p {
  color: #fff;
}

#social-media-sidebar {
  position: fixed;
  top: 40vh;
  left: 0;
  z-index: 999;
}

.side-social {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  background: #d0d5de;
  /* box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.7); */
  transition: 0.2s;
  cursor: pointer;
}

.social-media-sidebar li:before {
  content: none;
  display: none;
}

.social-media-sidebar li {
  padding: 0.111em 0.333em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
}

.side-social:hover {
  width: 50px;
  transition: 0.2s;
}

.facebook svg {
  fill: #474d59;
}

.facebook:hover {
  background-color: #415e9b;
}

.facebook:hover svg {
  fill: #ffffff;
}

.messenger svg {
  fill: #474d59;
}

.messenger:hover {
  background-color: #1578f2;
}

.messenger:hover svg {
  fill: #ffffff;
}

.whatsup svg {
  fill: #474d59;
}

.whatsup:hover {
  background-color: #4ec247;
}

.whatsup:hover svg {
  fill: #ffffff;
}

.facetime {
  fill: #474d59;
}

.facetime:hover {
  background-color: #6df857;
}

.facetime:hover svg {
  fill: #ffffff;
}

.instagram {
  background: #fccc63;
}

.google-plus {
  background: #dd4b39;
}

.services-card-section {
  padding: 50px 0;
  background: #f0f0f0;
}

.services-card-section h3 {
  text-align: center;
  text-transform: capitalize;
}

.services-card {
  display: block;
  border: 1px solid #f0f0f0;
  text-align: center;
  padding: 75px;
  background: #fff;
  height: 300px;
}

.services-card-title {
  text-transform: uppercase;
  display: block;
}

.services-card-img-icon {
  height: 280px;
  width: 212px;
  margin-bottom: 15px;
}

.service-card-js-slider .slick-prev,
.service-card-js-slider .slick-next {
  top: initial;
  bottom: -45px;
}

.service-card-js-slider .slick-slide img {
  display: inline-block;
}

.services-card-wrapper.desktop {
  gap: 0px;
}

.services-card-section .container {
  max-width: 510px;
}

@media (min-width: 768px) {
  .services-card-img-icon {
    height: 200px;
    width: 155px;
    margin-bottom: 15px;
  }

  .services-card {
    display: inline-block;
  }

  .services-card-section .container {
    max-width: 768px;
  }

  .services-card-wrapper.desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .services-card-wrapper.desktop > .services-card {
    width: 48%;
    height: 100%;
  }

  .services-card-wrapper.service-card-js-slider {
    display: none;
  }
}

@media (min-width: 1200px) {
  .services-card-section {
    padding: 70px 0;
  }

  .services-card-section .container {
    max-width: 1010px;
  }

  .services-card-wrapper.desktop > .services-card {
    width: 24%;
    padding: 10px;
  }
}

@media (min-width: 1400px) {
  .services-card-wrapper.desktop > .services-card {
    padding: 75px 75px;
  }
}

@media (min-width: 1800px) {
  .services-card-wrapper.desktop > .services-card {
    padding: 75px 126px;
  }
}

.services-card-img-icon {
  margin-bottom: 0px;
}

.job-app-title {
  padding-left: 15px;
  padding-top: 15px;
  background: #f8f8f8;
  margin-bottom: 0;
  border: 1px solid #bababa;
  border-bottom: none;
}

.job-app-title + .wpcf7 .wpcf7-form {
  background: #f8f8f8;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #bababa;
  border-top: none;
}

.job-app-title + .wpcf7 .wpcf7-form .wpcf7-free-text {
  max-width: 300px;
}

.grecaptcha-badge {
  z-index: 9999;
}

.hVYMdc {
  position: relative;
  margin-right: 10px;
}

/* .hGWAMb .telcocom-call-component {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out 0s;
} */
.hVYMdc button {
  width: 32px;
  height: 32px;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  background-color: rgb(254 201 50);
  animation-name: scale;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  position: relative;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.hVYMdc button::before {
  content: "";
  margin: -1px;
  z-index: -1;
  border-radius: 50%;
  background-color: #fec932;
  position: absolute;
  inset: 0px;
}

.hVYMdc button::after {
  content: "";
  margin: -4px;
  z-index: -2;
  border-radius: 50%;
  background-color: rgb(246, 246, 246);
  box-shadow: #fc0 0px 0px 8px;
  position: absolute;
  inset: 0px;
}

.hVYMdc .bg-anime {
  width: 140%;
  height: 140%;
  z-index: -1;
  opacity: 0.5;
  border-radius: 50%;
  background-color: #ffcd11;
  animation: 2s ease 0s infinite normal none running homeCover_pulse1__3AsKo;
  transition: all 0.3s ease-in-out 0s;
  position: absolute;
  left: -20%;
  top: -20%;
}

@keyframes homeCover_pulse1__3AsKo {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.specCheckLite a,
.specCheckLite abbr,
.specCheckLite acronym,
.specCheckLite address,
.specCheckLite article,
.specCheckLite aside,
.specCheckLite audio,
.specCheckLite b,
.specCheckLite big,
.specCheckLite blockquote,
.specCheckLite body,
.specCheckLite canvas,
.specCheckLite caption,
.specCheckLite center,
.specCheckLite cite,
.specCheckLite code,
.specCheckLite dd,
.specCheckLite del,
.specCheckLite details,
.specCheckLite dfn,
.specCheckLite div,
.specCheckLite dl,
.specCheckLite dt,
.specCheckLite em,
.specCheckLite embed,
.specCheckLite fieldset,
.specCheckLite figcaption,
.specCheckLite figure,
.specCheckLite footer,
.specCheckLite form,
.specCheckLite h1,
.specCheckLite h2,
.specCheckLite h3,
.specCheckLite h4,
.specCheckLite h5,
.specCheckLite h6,
.specCheckLite header,
.specCheckLite hgroup,
.specCheckLite html,
.specCheckLite i,
.specCheckLite img,
.specCheckLite ins,
.specCheckLite kbd,
.specCheckLite label,
.specCheckLite legend,
.specCheckLite li,
.specCheckLite mark,
.specCheckLite menu,
.specCheckLite nav,
.specCheckLite ol,
.specCheckLite output,
.specCheckLite p,
.specCheckLite pre,
.specCheckLite q,
.specCheckLite ruby,
.specCheckLite s,
.specCheckLite samp,
.specCheckLite section,
.specCheckLite small,
.specCheckLite span,
.specCheckLite strike,
.specCheckLite strong,
.specCheckLite sub,
.specCheckLite summary,
.specCheckLite sup,
.specCheckLite table,
.specCheckLite tbody,
.specCheckLite td,
.specCheckLite tfoot,
.specCheckLite th,
.specCheckLite thead,
.specCheckLite time,
.specCheckLite tr,
.specCheckLite tt,
.specCheckLite u,
.specCheckLite ul,
.specCheckLite var,
.specCheckLite video {
  background: 0 0;
  border: none;
  bottom: auto;
  clear: none;
  cursor: default;
  float: none;
  font-size: 100%;
  font-size: medium;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  overflow: visible;
  padding: 0;
  position: static;
  right: auto;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  top: auto;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  width: auto;
  z-index: auto;
}

.specCheckLite ol,
.specCheckLite ul {
  list-style: none;
}

.specCheckLite q:after,
.specCheckLite q:before {
  content: "";
  content: none;
}

.specCheckLite table {
  border-collapse: collapse;
  border-spacing: 0;
}

.scl-screen h1 {
  font-size: 1.2em;
  margin-bottom: 0.1em;
  margin-top: 0;
}

.scl-screen h1:empty,
.scl-screen h2:empty {
  display: none;
}

.scl-screen h2 {
  font-size: 0.9em;
  margin-top: 0;
}

.scl-screen .scl-button {
  -webkit-touch-callout: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.scl-screen .scl-powered-by {
  height: 1em;
  margin-top: 1em;
  text-align: right;
}

.scl-screen .scl-powered-by a {
  cursor: pointer;
}

.scl-screen .scl-powered-by a span {
  color: #666;
  font-weight: 700;
  line-height: 1em;
  margin-right: 0.2em;
  vertical-align: top;
}

.scl-screen .scl-powered-by a img,
.scl-screen .scl-powered-by a span {
  cursor: pointer;
  display: inline-block;
  height: 1em;
}

.specCheckLite span.scl-attrval {
  float: right;
  font-size: 0.75em;
}

.specCheckLite span.scl-attrval > span {
  font-size: inherit;
}

.specCheckLite span.scl-attrval span.scl-attrval-unit {
  font-weight: 700;
  margin-left: 0.2em;
}

.specCheckLite .scl-machine-tier:empty {
  display: none !important;
}

.specCheckLite .scl-machine-tier:before {
  content: "- ";
}

.specCheckLite .scl-compare-scroll-buttons {
  display: none;
}

#sclMachineSelection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#sclMachineSelection .scl-machineSelectPane {
  display: inline-block;
  flex: 1 1 auto;
  margin: 0.2em;
  width: 49.95%;
}

#sclMachineSelection .scl-machineSelectPane h1 {
  color: red;
  font-size: 2em;
}

#sclMachineSelection ul {
  list-style: none;
  padding: 0;
}

#sclInternalMachineSelectPane {
  margin-right: 1em;
}

#sclComparisonScreen .scl-back,
#sclMachineSelectScreen .scl-back,
.scl-button-compare {
  float: right;
}

#sclComparisonScreen .scl-attrdef,
#sclComparisonScreen .scl-attrdef .scl-attrdef-imperial,
#sclComparisonScreen .scl-attrdef .scl-attrdef-metric,
#sclComparisonScreen .scl-attrdef .scl-attrdef-unit,
#sclMachineSelectScreen .scl-attrdef,
#sclMachineSelectScreen .scl-attrdef .scl-attrdef-imperial,
#sclMachineSelectScreen .scl-attrdef .scl-attrdef-metric,
#sclMachineSelectScreen .scl-attrdef .scl-attrdef-unit {
  font-weight: 700;
}

#sclComparisonScreen .scl-section,
#sclMachineSelectScreen .scl-section {
  cursor: pointer;
  margin-bottom: 0.1em;
  padding: 0.4em;
}

#sclComparisonScreen table,
#sclMachineSelectScreen table {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
}

#sclComparisonScreen table td,
#sclComparisonScreen table th,
#sclMachineSelectScreen table td,
#sclMachineSelectScreen table th {
  padding: 0.4em;
}

#sclComparisonScreen table tbody th,
#sclMachineSelectScreen table tbody th {
  text-align: left;
  width: 10em;
}

#sclComparisonScreen table thead,
#sclComparisonScreen table thead tr,
#sclMachineSelectScreen table thead,
#sclMachineSelectScreen table thead tr {
  width: 100%;
}

#sclComparisonScreen table thead tr th,
#sclMachineSelectScreen table thead tr th {
  width: 20%;
}

.specCheckLite span.scl-attrval {
  float: none;
}

.specCheckLite .scl-screen {
  background-color: #fff;
  color: #1c1c1c;
}

.specCheckLite .scl-screen h1 {
  font-size: 26px;
  font-weight: 700;
  margin-top: 36px;
  text-transform: uppercase;
}

.specCheckLite .scl-screen h2 {
  border: none;
  font-size: 14px;
  font-weight: 700;
}

.specCheckLite .scl-screen .scl-button {
  background-color: #fc0;
  color: #1c1c1c;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.16px;
  margin: 0 16px 10px 10px;
  padding: 14px 16px 14px 24px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
  width: 41.66666667%;
}

.specCheckLite .scl-screen .scl-button:hover {
  background: #666;
  color: #fff;
}

.specCheckLite .scl-screen .scl-button:hover:after {
  background: #666;
}

.specCheckLite .scl-screen .scl-button:after {
  background: #fc0;
  -webkit-clip-path: polygon(1px 0, 100% 50%, 1px 100%, 0 100%, 0 0);
  clip-path: polygon(1px 0, 100% 50%, 1px 100%, 0 100%, 0 0);
  content: "";
  height: 100%;
  position: absolute;
  right: -17px;
  top: 0;
  transition: all 0.4s ease-in-out;
  width: 18px;
}

.specCheckLite .scl-screen .scl-back-alt,
.specCheckLite .scl-screen .scl-button-compare-alt {
  display: none;
}

.specCheckLite .scl-screen .scl-exports {
  margin: 1em 0;
}

.specCheckLite .scl-screen .scl-exports .scl-button {
  float: none;
  margin-right: 0.5em;
}

.specCheckLite #sclMachineSelectScreen ul,
.specCheckLite #sclRangeSelectScreen ul {
  align-content: flex-end;
  align-items: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  justify-content: space-between;
  width: 100%;
}

.specCheckLite #sclMachineSelectScreen ul li,
.specCheckLite #sclRangeSelectScreen ul li {
  width: 48%;
}

.specCheckLite #sclMachineSelectScreen ul li:not(:first-child):not(:nth-child(2)),
.specCheckLite #sclRangeSelectScreen ul li:not(:first-child):not(:nth-child(2)) {
  border-top: 1px solid #ddd;
}

.specCheckLite #sclMachineSelectScreen ul li a,
.specCheckLite #sclRangeSelectScreen ul li a {
  align-items: center;
  color: #1c1c1c;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  padding: 15px;
}

.specCheckLite #sclMachineSelectScreen ul li a:active,
.specCheckLite #sclMachineSelectScreen ul li a:hover,
.specCheckLite #sclRangeSelectScreen ul li a:active,
.specCheckLite #sclRangeSelectScreen ul li a:hover {
  background-color: #f5f5f5;
}

.specCheckLite #sclMachineSelectionScreen #sclAttributeSelection {
  align-items: center;
  clear: both;
  display: flex;
}

.specCheckLite #sclMachineSelectionScreen #sclAttributeSelection label {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  width: auto;
}

.specCheckLite #sclMachineSelectionScreen #sclAttributeSelection select {
  -moz-appearance: auto;
  appearance: auto;
  -webkit-appearance: auto;
  display: inline-block;
  width: auto;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane {
  flex: 1 100%;
  margin: 0;
  position: relative;
  width: 100%;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane .scl-title {
  font-size: 18px;
  padding-left: 0;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane .scl-paneHead {
  margin-bottom: 12px;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane ul li {
  display: flex;
  padding: 5px 0;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane ul li:before {
  display: none;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane ul li label {
  align-items: center;
  display: flex;
  flex: 1 100%;
  justify-content: space-between;
  padding-left: 6px;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane ul li label .scl-attrval span {
  font-weight: 700;
}

.specCheckLite
  #sclMachineSelectionScreen
  #sclMachineSelection
  .scl-machineSelectPane
  ul
  li
  label
  .scl-attrval
  span.scl-attrval-unit {
  font-weight: 400;
}

.specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane:first-child:after {
  background-color: #ddd;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: -12px;
  top: 0;
  width: 1px;
}

.specCheckLite #sclComparisonScreen table td,
.specCheckLite #sclComparisonScreen table th {
  padding: 5px;
  text-align: center;
}

.specCheckLite #sclComparisonScreen table tr.scl-modelNameRow th span {
  display: block;
  font-weight: 700;
  text-align: center;
}

.specCheckLite #sclComparisonScreen table tr.scl-modelNameRow th span:first-child {
  font-weight: 400;
}

.specCheckLite #sclComparisonScreen table tbody.data td {
  font-size: 14px;
  text-align: center;
}

.specCheckLite #sclComparisonScreen table tbody.data th {
  background: #fff;
  border-right: 1px solid #ddd;
  font-size: 14px;
  text-align: left;
  font-weight: 700;
}

.specCheckLite #sclComparisonScreen table tbody.data th span {
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
}

.specCheckLite #sclComparisonScreen table tbody.data th span.scl-attrdef span {
  font-weight: 400;
}

.specCheckLite #sclComparisonScreen table tbody.data tr:not(:first-child) {
  border-top: 1px solid #ddd;
}

.specCheckLite #sclComparisonScreen table tbody.data tr.scl-data-row:hover,
.specCheckLite #sclComparisonScreen table tbody.data tr.scl-data-row:hover th {
  background-color: #f5f5f5;
}

.specCheckLite #sclComparisonScreen table tbody.scl-section th {
  padding: 0;
  pointer-events: none;
}

.specCheckLite #sclComparisonScreen table tbody.scl-section th > span {
  align-items: center;
  color: #1c1c1c;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 15px 15px 0;
}

@media only screen and (max-width: 767px) {
  .specCheckLite #sclMachineSelectScreen ul,
  .specCheckLite #sclRangeSelectScreen ul {
    flex-direction: column;
  }

  .specCheckLite #sclMachineSelectScreen ul li,
  .specCheckLite #sclRangeSelectScreen ul li {
    width: 100%;
  }

  .specCheckLite #sclMachineSelectScreen ul li:nth-child(2),
  .specCheckLite #sclRangeSelectScreen ul li:nth-child(2) {
    border-top: 1px solid #ddd;
  }

  .specCheckLite #sclMachineSelectionScreen #sclMachineSelection {
    flex-direction: column;
  }

  .specCheckLite #sclMachineSelectionScreen #sclMachineSelection .scl-machineSelectPane:first-child:after {
    display: none;
  }

  .specCheckLite #sclComparisonScreen .scl-comparisongrid-holder {
    overflow-x: auto;
    width: 100%;
  }

  .specCheckLite #sclComparisonScreen .scl-comparisongrid-holder table tr > th:first-child,
  .specCheckLite #sclComparisonScreen .scl-comparisongrid-holder table tr > th:first-child > span {
    left: 0;
    position: sticky;
  }
}

@media (max-width: 600px) {
  .specCheckLite .scl-screen .scl-button {
    width: 50%;
  }
}

.specCheckLite #sclComparisonScreen table tbody.scl-section th > span {
  align-items: center;
  color: #1c1c1c;
  cursor: pointer;
  display: flex;
  font-family: sans-serif;
  font-family: Source Sans Pro, sans-serif;
  font-size: 24px;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 15px 15px 0;
}

.specCheckLite #sclComparisonScreen table tbody.scl-section th {
  padding: 0;
  pointer-events: none;
}

.text-center {
  text-align: center;
}

#sclComparisonScreen > table > thead > tr > th > span.scl-font-bold {
  font-weight: 700;
  font-size: 15px;
}

.gnewtonNo.isValid,
.gnewtonYes.isValid {
  background: #fff;
  border: solid #ccd3d8 1px;
}

.gnewtonNo.isValid.isSelected,
.gnewtonYes.isValid.isSelected {
  background: #f0f8fc;
  border: solid #3498db 1px;
  box-shadow: inset 0 0 8px #3498db;
}

.ApplicationBodyClass div.dashed {
  border-top: 1px dashed #ccd3d8;
  margin: 4px 0;
}

.gnewtonSectionClass dl {
  border-bottom: 1px dashed #ccd3d8;
  margin-top: 8px;
  padding: 0 0 4px;
}

.custom-fileUploader {
  border: 1px dashed #ccd3d8 !important;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.custom-section {
  border: 1px dashed #ccd3d8 !important;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.search-form input,
input::placeholder {
  font-size: 13px;
}

.locale {
  color: #feca32 !important;
  /* darker yellow */
  background-color: #fff;
  /* white */
}
